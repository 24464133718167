import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { Row } from "../../components/Row";
import { SKHotkey } from "../../types/sk";
import { bqaDarkGrey } from "../../util/colors";
import { getHotkeyDescription } from "../../util/skUtils";
import { SET_SK_HOTKEYS } from "../../state/reducers/sk";

export function EditHotkeys() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hotkeys, selectedSeason, selectedEvent } = useSelector(
    (state: RootStore) => state.sk
  );
  const { skAdvanced } = useSelector((state: RootStore) => state.settings);
  const [hotkeyEditing, setHotkeyEditing] = useState<SKHotkey | undefined>(
    undefined
  );

  useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      if (hotkeyEditing) {
        const blacklist: string[] = [
          "Shift",
          "Control",
          "Alt",
          "Meta",
          "Command",
          "Tab",
          "Context",
          "Function",
        ];
        let allowed = true;
        blacklist.forEach((item: string) => {
          if (e.code.startsWith(item)) allowed = false;
        });
        if (!allowed) return;
        dispatch({
          type: SET_SK_HOTKEYS,
          payload: {
            hotkeys: {
              ...hotkeys,
              [hotkeyEditing]: e.code,
            },
          },
        });
        setHotkeyEditing(undefined);
      }
    };
    document.addEventListener("keyup", keyHandler);
    return () => {
      document.removeEventListener("keyup", keyHandler);
    };
  });

  const EditableHotkeyItem = (props: {
    hotkey: SKHotkey;
    description: string;
    extendedDescription?: string;
    advanced?: boolean;
  }) => {
    if (props.advanced && !skAdvanced) return null;
    return (
      <>
        <Row
          style={{
            marginTop: 6,
            borderTop: `1px solid ${bqaDarkGrey}`,
            paddingTop: 8,
          }}
        >
          <div style={{ width: 220 }}>{props.description}</div>
          <div style={{ width: 100 }}>
            {getHotkeyDescription(hotkeys[props.hotkey])}
          </div>
          <button
            className="link"
            style={{ marginRight: 10 }}
            onClick={() => {
              if (hotkeyEditing === props.hotkey) {
                setHotkeyEditing(undefined);
              } else {
                setHotkeyEditing(props.hotkey);
              }
            }}
          >
            {hotkeyEditing === props.hotkey ? "Cancel" : "Change"}
          </button>
          {hotkeys[props.hotkey] !== undefined && (
            <button
              className="link"
              onClick={() => {
                dispatch({
                  type: SET_SK_HOTKEYS,
                  payload: {
                    hotkeys: {
                      ...hotkeys,
                      [props.hotkey]: undefined,
                    },
                  },
                });
              }}
            >
              Clear
            </button>
          )}
        </Row>
        {props.extendedDescription && (
          <p style={{ marginTop: 0, fontSize: 12 }}>
            {props.extendedDescription}
          </p>
        )}
      </>
    );
  };

  return (
    <div className="page" style={{ minWidth: 400 }}>
      <Row>
        <button className="back-button" onClick={() => navigate(-1)}>
          Go Back
        </button>
        <button
          className="clickable"
          style={{ marginLeft: 10 }}
          onClick={() => {
            navigate(
              `/sk${
                selectedSeason
                  ? `/${selectedSeason}${
                      selectedEvent ? `/${selectedEvent}` : ""
                    }`
                  : ""
              }`
            );
          }}
        >
          SK Menu
        </button>
        <h1 style={{ marginLeft: 10 }}>Scorekeeper Hotkeys</h1>
      </Row>
      <p>
        If you are using a keyboard, there are many hotkeys you can use to speed
        up scorekeeping. You can see all of them here, and even edit them if it
        will make your scorekeeping experience better, or if a key on your
        keyboard doesn't work.
      </p>
      <p style={{ marginBottom: 20 }}>
        Note that some hotkeys will require you to hold down the shift key as a
        modifier, regardless of where what you set the hotkey to. You may have
        conflict between two hotkeys only if one requires Shift and the other
        doesn't.
      </p>
      <EditableHotkeyItem description="Open Show Team View" hotkey="showTeam" />
      <EditableHotkeyItem description="Open Play-By-Play" hotkey="pbp" />
      <EditableHotkeyItem
        description="Open Running Score"
        hotkey="runningScore"
      />
      <EditableHotkeyItem
        advanced
        description="Open Win Probabilities"
        hotkey="winProbabilities"
      />
      <EditableHotkeyItem description="Halftime (Shift)" hotkey="halftime" />
      <EditableHotkeyItem description="Overtime (Shift)" hotkey="overtime" />
      <EditableHotkeyItem
        description="Thrown-Out Question (Shift)"
        hotkey="throwout"
      />
      <EditableHotkeyItem
        description="No Question (Shift)"
        hotkey="noQuestion"
      />
      <EditableHotkeyItem
        description="Toggle Blue Bar"
        hotkey="toggleSelection"
        extendedDescription="Toggles whether the number keys select a reference or a quizzer"
      />
      <EditableHotkeyItem
        description="Assign Points or Errors"
        hotkey="assign"
      />
      <EditableHotkeyItem description="Go Up" hotkey="goUp" />
      <EditableHotkeyItem description="Go Down" hotkey="goDown" />
      <EditableHotkeyItem description="Go Right" hotkey="goRight" />
      <EditableHotkeyItem description="Go Left" hotkey="goLeft" />
      <EditableHotkeyItem
        description="Go Back"
        hotkey="goBack"
        extendedDescription="Goes back one number in selecting a reference"
      />
      <EditableHotkeyItem description="Colon" hotkey="colon" />
      <EditableHotkeyItem advanced description="Quote" hotkey="quote" />
      <EditableHotkeyItem description="Clear Selection" hotkey="clearAll" />
      <EditableHotkeyItem
        description="Undo Last Question (Shift)"
        hotkey="undo"
      />
      <EditableHotkeyItem
        description="Reverse Teams (Shift)"
        hotkey="reverseTeams"
      />
      {Array(10)
        .fill(undefined)
        .map((_, index) => (
          <EditableHotkeyItem
            description={`Number ${index}`}
            hotkey={("num" + index) as SKHotkey}
            key={index}
          />
        ))}
    </div>
  );
}
