import * as types from "../types/sk";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";

// CRUD with localStorage for caching
export const getLocalSeasonSummaries = (): types.SeasonSummary[] => {
  return JSON.parse((localStorage.getItem("seasons") as string) || "[]");
};
export const setLocalSeasonSummaries = (
  seasonSummaries: types.SeasonSummary[]
): void => {
  localStorage.setItem("seasons", JSON.stringify(seasonSummaries));
};
export const setLocalSeason = (season: types.Season) => {
  localStorage.setItem(`season-${season.seasonId}`, JSON.stringify(season));
};
export const addSeasonToLocalStore = (season: types.Season) => {
  const summaries = getLocalSeasonSummaries();
  if (!summaries.some((summary) => summary.seasonId === season.seasonId))
    summaries.push({
      seasonId: season.seasonId,
      seasonName: season.seasonName,
      location: "LOCAL",
      isPractice: season.isPractice,
      lastUpdated: dayjs().format(),
    });
  setLocalSeasonSummaries(summaries);
  setLocalSeason(season);
};
export const getLocalSeason = (seasonId: string): types.Season | undefined => {
  const local = localStorage.getItem(`season-${seasonId}`) as string;
  if (!local) return undefined;
  return { events: [], ...JSON.parse(local), location: "LOCAL" };
};
export const deleteLocalSeason = (seasonId: string): void => {
  localStorage.removeItem(`season-${seasonId}`);
};

// Saving a quiz. Note that you'll need the season or event to get the quiz id so you can find it
export const getLocalQuiz = (quizId: string): types.Quiz | undefined => {
  const quiz = JSON.parse(localStorage.getItem(`quiz-${quizId}`) as string);
  if (!quiz) return undefined;
  return {
    ...quiz,
    location: "LOCAL",
  };
};
export const setLocalQuiz = (quiz: types.Quiz): void => {
  localStorage.setItem(`quiz-${quiz.quizId}`, JSON.stringify(quiz));
};
export const deleteLocalQuiz = (quizId: string): void => {
  localStorage.removeItem(`quiz-${quizId}`);
};

/**
 * gets a better description for a hotkey
 * @param hotkey the key code iself
 * @returns description of the hotkey to be displayed
 */
export const getHotkeyDescription = (hotkey: string | undefined): string => {
  if (!hotkey) return "----";
  if (hotkey.startsWith("Key")) return hotkey.substring(3);
  return hotkey;
};

// Gets the box score, a summary of the quiz with points and errors. Does not contain detailed information, such as seat time or jumping statistics.
export const getDefaultBoxScore = (
  teams: types.Team[],
  isPractice: boolean = false
): Readonly<types.BoxScore> => {
  return {
    quizStatus: types.firstHalf,
    teams: teams.map((team: types.Team) => ({
      teamId: team?.teamId || uuid(),
      teamName: team.teamName,
      points: 0,
      errors: 0,
      quizzersAnswering: 0,
      quizzers:
        team?.quizzers?.map((quizzer: types.Quizzer) => ({
          quizzerId: quizzer.quizzerId,
          quizzerName: quizzer.quizzerName,
          points: 0,
          errors: 0,
          foul: false,
          eligable: true,
        })) || [],
      eligable: true,
      coachesTimeoutAvailable: !isPractice,
      captain: team.captian,
    })),
    finalPlacements: teams.map(() => 0),
    totalQuestions: 0,
    isPractice,
  };
};

export const generateBoxScore = (
  quiz: types.Quiz | undefined,
  teams: types.Team[],
  includeOvertime: boolean = true,
  replaySettings?: types.ReplayQuizSettings
): types.BoxScore => {
  const box: types.BoxScore = {
    ...getDefaultBoxScore(teams, quiz?.isPractice),
  };
  if (!quiz) return box;

  quiz.moments.forEach((moment: types.Moment) => {
    if (box.quizStatus !== types.overtime || includeOvertime) {
      if (
        !replaySettings ||
        box.totalQuestions < replaySettings.selectedQuestion
      )
        doMoment(box, moment);
    }
  });

  return box;
};

// Applies the moment directly to the provided box score, and returns the box score
export const doMoment = (
  box: types.BoxScore,
  moment: types.Moment
): types.BoxScore => {
  const applyTeamError = (teamIndex: number): void => {
    box.teams[teamIndex].errors += 1;
    if (box.teams[teamIndex].errors > 3) {
      box.teams[teamIndex].points -= box.teams[teamIndex].errors > 6 ? 20 : 10;
    }
  };

  if (isQuestion(moment)) box.totalQuestions++;
  if (moment.team !== undefined && box.teams[moment.team]) {
    if (moment.quizzerId) {
      // Something happened to a specific quizzer
      const quizzerIndex = box.teams[moment.team].quizzers.findIndex(
        (quizzer: types.QuizzerBoxScore) =>
          quizzer.quizzerId === moment.quizzerId
      );
      let originallyEligable: boolean =
        box.teams[moment.team].quizzers[quizzerIndex].eligable;
      switch (moment.type) {
        case types.tp:
          const pointsToAdd = box.isPractice ? 1 : 20;
          box.teams[moment.team].quizzers[quizzerIndex].points += pointsToAdd;
          box.teams[moment.team].points += pointsToAdd;

          // Quiz Out Perfect Score
          if (
            box.teams[moment.team].quizzers[quizzerIndex].points === 100 &&
            !box.isPractice
          ) {
            box.teams[moment.team].quizzers[quizzerIndex].eligable = false;
            if (box.teams[moment.team].quizzers[quizzerIndex].errors === 0) {
              box.teams[moment.team].quizzers[quizzerIndex].points += 10;
              box.teams[moment.team].points += 10;
            }
          }

          // Quizzers Answering and Bonuses
          if (box.teams[moment.team].quizzers[quizzerIndex].points === 20) {
            box.teams[moment.team].quizzersAnswering += 1;
            if (box.teams[moment.team].quizzersAnswering >= 5)
              box.teams[moment.team].points += 20;
          }
          break;
        case types.error:
          box.teams[moment.team].quizzers[quizzerIndex].errors += 1;
          if (!box.isPractice) {
            if (box.teams[moment.team].quizzers[quizzerIndex].errors === 3) {
              box.teams[moment.team].quizzers[quizzerIndex].eligable = false;
            }
            applyTeamError(moment.team);
          }
          break;
        case types.foul:
          box.teams[moment.team].quizzers[quizzerIndex].foul =
            !box.teams[moment.team].quizzers[quizzerIndex].foul;
          if (!box.teams[moment.team].quizzers[quizzerIndex].foul) {
            // False, so it was true, so apply a quizzer error
            box.teams[moment.team].quizzers[quizzerIndex].errors += 1;
          }
          let teamError: boolean = true;
          box.teams[moment.team].quizzers.forEach(
            (quizzer: types.QuizzerBoxScore) => {
              if (quizzer.foul) teamError = !teamError;
            }
          );
          if (teamError) applyTeamError(moment.team);
          break;
        case types.absent:
          box.teams[moment.team].quizzers[quizzerIndex].eligable = false;
      }
      if (
        originallyEligable &&
        !box.teams[moment.team].quizzers[quizzerIndex].eligable
      ) {
        // This quizzer became ineligable as a result of this question
        if (box.teams[moment.team].captain === quizzerIndex)
          box.teams[moment.team].captain = undefined;
      }
    }
    switch (moment.type) {
      case types.tf:
        box.teams[moment.team].points -= 10;
        break;
      case types.ct:
        box.teams[moment.team].coachesTimeoutAvailable = false;
    }
  } else {
    switch (moment.type) {
      case types.halftime:
        box.quizStatus = types.secondHalf;
        box.teams.forEach((team: types.TeamBoxScore) => {
          team.coachesTimeoutAvailable = true;
        });
        break;
      case types.startOvertime:
        box.quizStatus = types.overtime;
        box.teams.forEach((team: types.TeamBoxScore, teamIndex: number) => {
          if (team.eligable && box.finalPlacements[teamIndex] === 0) {
            // Potentially remove the team
            let teamsBeat = 0,
              teamsTied = 0;
            box.teams.forEach(
              (compareTeam: types.TeamBoxScore, compareTeamIndex: number) => {
                if (compareTeamIndex !== teamIndex) {
                  if (box.finalPlacements[compareTeamIndex] === 1) {
                    // Empty
                  } else if (
                    box.finalPlacements[compareTeamIndex] === box.teams.length
                  ) {
                    teamsBeat += 1;
                  } else if (team.points > compareTeam.points) {
                    teamsBeat += 1;
                  } else if (team.points < compareTeam.points) {
                    // Empty
                  } else {
                    teamsTied += 1;
                  }
                }
              }
            );
            if (teamsTied === 0) {
              // Remove the team
              team.eligable = false;
              team.coachesTimeoutAvailable = false;
              box.finalPlacements[teamIndex] = box.teams.length - teamsBeat;
            }
          }
        });
        break;
      case types.endQuiz:
        applyEndQuiz(box);
        break;
    }
  }

  return box;
};
export const applyEndQuiz = (
  box: types.BoxScore,
  splitPlacements: boolean = false
): void => {
  box.quizStatus = types.ended;
  const resultPlacements = [...box.finalPlacements];
  box.teams.forEach((team: types.TeamBoxScore, teamIndex: number) => {
    if (team.eligable) {
      team.coachesTimeoutAvailable = false;
      let teamsBeat: number = 0;
      box.teams.forEach(
        (compareTeam: types.TeamBoxScore, compareIndex: number) => {
          if (teamIndex === compareIndex) return; // Make sure we're comparing different teams
          if (box.finalPlacements[compareIndex] === 3) {
            // Third place; automatic win
            teamsBeat += 1;
          } else if (!box.finalPlacements[compareIndex]) {
            // We beat them
            if (compareTeam.points < team.points) teamsBeat += 1;
            // For Win Prob: we tied, so split the placement
            if (compareTeam.points === team.points && splitPlacements)
              teamsBeat += 0.5;
          }
        }
      );
      resultPlacements[teamIndex] = box.teams.length - teamsBeat;
    }
  });
  box.finalPlacements = resultPlacements;
};

/**
 * @param boxScore
 * @param teamIndex
 * @param quizzerIndex
 * @returns if the quizzer is eligable to jump in upcoming questions
 */
export const isQuizzerEligable = (
  boxScore: types.BoxScore,
  teamIndex: number,
  quizzerIndex: number
): boolean => {
  const teamBox: types.TeamBoxScore = boxScore.teams[teamIndex];
  return teamBox.eligable && teamBox.quizzers[quizzerIndex].eligable;
};

/**
 * @param teams the teams in the quiz
 * @param moments the list of moments
 * @param showAll if we want to show all moments, not just the questions
 * @returns [momentIndex][teamIndex] the running score of the resulting quiz
 */
export const getRunningScore = (
  teams: types.Team[],
  moments: types.Moment[],
  showAll: boolean = false
): types.RunningScoreItem[] => {
  const scoresByMoment: types.RunningScoreItem[] = [];
  const box: types.BoxScore = { ...getDefaultBoxScore(teams) };
  moments.forEach((moment: types.Moment) => {
    doMoment(box, moment);
    if (showAll || isQuestion(moment))
      scoresByMoment.push({
        scores: box.teams.map((team: types.TeamBoxScore) =>
          team.eligable ? team.points : undefined
        ),
        startOfPeriod:
          moment.type === types.halftime || moment.type === types.startOvertime,
        team: moment.team,
        quizErrorOut:
          isQuestion(moment) &&
          moment.quizzerId !== undefined &&
          moment.team !== undefined &&
          !box.teams[moment.team].quizzers.find(
            (quizzer: types.QuizzerBoxScore) =>
              quizzer.quizzerId === moment.quizzerId
          )?.eligable,
      });
    if (
      !showAll &&
      (moment.type === types.halftime || moment.type === types.startOvertime) &&
      scoresByMoment.length > 0
    )
      scoresByMoment[scoresByMoment.length - 1].startOfPeriod = true;
    if (!showAll && moment.type === types.ct && scoresByMoment.length > 0)
      scoresByMoment[scoresByMoment.length - 1].coachesTimeout = moment.team;
  });
  return scoresByMoment;
};

export const isQuestion = (moment: types.Moment): boolean => {
  return ([types.tp, types.error] as types.MomentType[]).includes(moment.type);
};
export const isStartPeriod = (moment: types.Moment): boolean => {
  return [types.halftime, types.startOvertime].includes(moment.type);
};
export const getWordsReceived = (
  moment: types.Moment
): { words: number; read: boolean } => {
  // Case for split reference
  if (
    (moment.receivedCharacters && moment.receivedCharacters < 0) ||
    moment.errorType === "SR"
  )
    return { words: -1, read: false };
  if (moment.verse === undefined) return { words: -1, read: false };

  if (moment.question === undefined) return { words: 0, read: false };
  if (moment.question === "") return { words: 0, read: moment.read || false };
  const received = moment.question.substring(0, moment.receivedCharacters);
  if (!received) return { words: 0, read: moment.read || false };
  const wordParts = received.split(" ");
  let fullWords = wordParts.length - 1;
  let read = false;
  // If there's another part of the word, we have a read
  if (!received.endsWith(" ")) {
    read = true;
  }
  return { words: fullWords, read: moment.read || read };
};
export const sides: Readonly<string[]> = ["Left", "Center", "Right"];
export const teamSideColors: Readonly<string[]> = ["blue", "red", "green"];
