import React, { useEffect, useState, TouchEvent, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { Row } from "../../components/Row";
import { HelpButton } from "../../components/HelpButton";
import { Check } from "../../components/Check/Check";
import { DropdownMenu } from "../../components/Dropdown/DropdownMenu";
import { QuizTimer } from "../../components/SKComponents/QuizTimer";
import { PlusMinusButton } from "../../components/PlusMinusButton";
import { NumberInput } from "../../components/Inputs";
import { ShowTeam } from "./SKScreens/ShowTeam";
import { PlayByPlay } from "./SKScreens/PlayByPlay";
import { RunningScore } from "./SKScreens/RunningScore";
import { WinProbabilities } from "./SKScreens/WinProbabilities";
import { EditDetails } from "./SKScreens/EditDetails";
import { useDataProvider } from "../../hooks/dataProvider";
import { useRequestHandler } from "../../hooks/requestHandler";
import { useKeyedParams } from "../../hooks/useParams";
import * as colors from "../../util/colors";
import * as utils from "../../util/skUtils";
import * as statsUtils from "../../util/skStatsUtils";
import * as swiping from "../../util/swiping";
import { getShortenedBook } from "../../util/generalUtil";
import * as skTypes from "../../types/sk";
import { Swiping } from "../../types/generalTypes";
import {
  createQuiz,
  deleteQuiz,
  getQuiz,
  getSeasonInfo,
  setSelectedQuiz,
  setSelectedSeason,
  updateQuiz,
} from "../../state/actions/skActions";
import { ReactComponent as EyeIcon } from "../../icons/skIcons/icon-eye.svg";
import { ReactComponent as WrenchIcon } from "../../icons/skIcons/icon-wrench.svg";
import "./SK.scss";
import "./QuizSite.scss";
import { QMRatingSelection } from "../../components/SKComponents/QMRating";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

interface QuizSiteProps {
  seasonId: string;
  eventId: string;
  quizId: string;
}
interface QuizSiteWatchingProps {
  quizId: string;
}

// Percentages for widths in team display
const quizzerSeatWidth = 8;
const quizzerNameWidth = 51;
const quizzerPointsWidth = 25;
const quizzerErrorsWidth = 15;

export function QuizSite() {
  const { seasonId, eventId, quizId } = useKeyedParams<QuizSiteProps>();
  const dispatch = useDispatch();
  const provider = useDataProvider({
    id: quizId,
    selector: (state) => state.sk.selectedQuiz,
    getId: (quiz) => quiz.quizId,
    getData: async (id: string) => {
      return await getQuiz(id, !(seasonId || eventId));
    },
    dispatchAction: setSelectedQuiz,
    render: (quiz) => {
      return (
        <QuizSiteComponent
          seasonId={seasonId}
          eventId={eventId}
          quiz={quiz}
          watching={false}
        />
      );
    },
    cacheDetails: {
      prefix: "quiz",
      get: utils.getLocalQuiz,
      save: utils.setLocalQuiz,
    },
    onUseFallback: () => {
      if (seasonId && eventId)
        dispatch(deleteQuiz(quizId, eventId, seasonId, false));
    },
  });

  return provider.getPage();
}

export function QuizSiteWatching() {
  const { quizId } = useKeyedParams<QuizSiteWatchingProps>();
  const provider = useDataProvider({
    id: quizId,
    selector: (state) => state.sk.selectedQuiz,
    getId: (quiz) => quiz.quizId,
    getData: (id: string) => getQuiz(id, true),
    dispatchAction: setSelectedQuiz,
    render: (quiz) => {
      return <QuizSiteComponent seasonId="" eventId="" quiz={quiz} watching />;
    },
  });

  return provider.getPage();
}

interface Props {
  seasonId: string;
  eventId: string;
  quiz: skTypes.Quiz;
  watching: boolean;
}
export function QuizSiteComponent(props: Props) {
  useDocumentTitle("Scorekeeper - Bible Quiz Academy");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { quiz, seasonId, eventId, watching } = props;
  const { user } = useSelector((state: RootStore) => state.authentication);
  const { selectedSeason } = useSelector((state: RootStore) => state.sk);

  useEffect(() => {
    if (quiz.location === "LOCAL" && user)
      dispatch(
        createQuiz({ ...quiz, seasonId, eventId }, true, selectedSeason)
      );
    if (quiz.location === "BEHIND") dispatch(updateQuiz(quiz));
  }, []);
  const materialKnown = useMemo(
    () =>
      statsUtils.getMaterialKnown(
        selectedSeason,
        quiz.teams.map((team) => team.teamId)
      ),
    [selectedSeason]
  );
  const handler = useRequestHandler({
    onSuccess: (key) => {
      switch (key) {
        case "DELETE":
          navigate(`/sk/${seasonId}/${eventId}`);
      }
    },
    loading: (state) => state.sk.skRequestLoading,
    assumeCachedInfo: true,
    alwaysSend: true,
  });
  const location = useLocation();
  const view = (location.state?.skView as string) || "SK";
  const material = useSelector((state: RootStore) => state.material.material);
  const settings = useSelector((state: RootStore) => state.settings);
  const {
    touchscreen,
    skAdvanced: advanced,
    skCompact: compactView,
    skIncludeOvertime,
  } = settings;
  const { hotkeys } = useSelector((state: RootStore) => state.sk);

  const seasonProvider = useDataProvider({
    id: seasonId,
    getId: (season) => season.seasonId,
    dispatchAction: setSelectedSeason,
    getData: getSeasonInfo,
    selector: (state) => state.sk.selectedSeason,
  });
  const teams = quiz?.teams || [];
  const [reverseTeams, setReverseTeams] = useState<boolean>(false);
  const [momentEditing, setMomentEditing] = useState<number | undefined>();
  const [replaySettings, setReplaySettings] = useState<
    skTypes.ReplayQuizSettings | undefined
  >(undefined);
  const originalBoxScore = useMemo(() => {
    return utils.generateBoxScore(quiz, teams);
  }, [quiz, teams]);
  const boxScore = useMemo(() => {
    return replaySettings
      ? utils.generateBoxScore(quiz, teams, true, replaySettings)
      : originalBoxScore;
  }, [replaySettings, quiz, teams, originalBoxScore]);

  const quizEditable =
    boxScore.quizStatus !== skTypes.ended && !watching && !replaySettings;

  const [subbedOut, setSubbedOut] = useState<string[]>([]); // Quizzer Ids
  const [mouseInQs, setMouseInQs] = useState<boolean>(false);
  // Used for SK compact view
  const [teamViewing, setTeamViewing] = useState<number | undefined>(undefined);
  const toggleMouseInQs = (): void => {
    setMouseInQs(!mouseInQs);
  };

  const addMoment = (newMoment: skTypes.Moment): void => {
    if (watching) return;
    const newQuiz: skTypes.Quiz = {
      ...quiz,
      moments: [...quiz.moments],
    };

    if (utils.isQuestion(newMoment) || newMoment.type === skTypes.throwout) {
      // If selected, add question data to moment
      if (!ghostSelection) {
        if (selectedBook > 0 && (books.length > 1 || selectedChapter > 0))
          newMoment.book = selectedBook - 1;
        if (selectedChapter > 0) newMoment.chapter = selectedChapter - 1;
        if (selectedVerse > 0) newMoment.verse = selectedVerse - 1;
        if (unknownFinalWord) {
          newMoment.question = selectedWords.substring(0, receivedCharacters);
        } else {
          newMoment.question = selectedWords;
        }
        newMoment.receivedCharacters = receivedCharacters;
      }

      if (newMoment.type !== skTypes.throwout) {
        const onBench: string[] = [];
        teams.forEach((team: skTypes.Team, teamIndex) => {
          if (boxScore.teams[teamIndex].eligable) {
            team.quizzers.forEach(
              (quizzer: skTypes.Quizzer, quizzerIndex: number) => {
                if (
                  (quizzerIndex >= 5 ||
                    (boxScore.teams[teamIndex].quizzers[quizzerIndex]
                      .eligable &&
                      subbedOut.includes(quizzer.quizzerId))) &&
                  quizzer.quizzerId !== newMoment.quizzerId
                )
                  onBench.push(quizzer.quizzerId);
              }
            );
          }
        });
        newMoment.onBench = onBench;
      }
    }
    if (momentEditing !== undefined) {
      newQuiz.moments[momentEditing] = newMoment;
    } else {
      newQuiz.moments.push(newMoment);
    }
    setMomentEditing(undefined);
    handler.runRequest(updateQuiz(newQuiz, user !== undefined));

    setItemSelected(undefined);
    setAssigningErrors(false);
    setAssigningKError(false);
    setMouseInQs(true);
    setGhostSelection(true);
  };

  const [broadcastError, setBroadcastError] = useState<boolean>(false);
  const [timeUntilRefresh, setTimeUntilRefresh] = useState<number>(8);
  const refresh = () => {
    // For watching only
    if (!watching) return;
    setTimeUntilRefresh(8);
    getQuiz(quiz.quizId, watching).then((newQuiz) => {
      if (newQuiz) {
        dispatch(setSelectedQuiz(newQuiz));
        setBroadcastError(false);
      } else {
        setBroadcastError(true);
      }
    });
  };
  useEffect(() => {
    if (watching && timeUntilRefresh <= 0) refresh();
  }, [timeUntilRefresh]);
  useEffect(() => {
    if (watching) {
      refresh();
      const timeoutId = setInterval(() => {
        setTimeUntilRefresh((a: number) => a - 1);
      }, 1000);
      return () => clearInterval(timeoutId);
    }
  }, []);

  const quizzerName: Readonly<ThingSelectedType> = "QUIZZER_NAME";
  const points: Readonly<ThingSelectedType> = "POINTS";
  const errors: Readonly<ThingSelectedType> = "ERRORS";
  type ThingSelectedType = "QUIZZER_NAME" | "POINTS" | "ERRORS" | "";
  // What we currently have selected
  const [tentativeTeamSelection, setTentativeTeamSelection] = useState<
    number | undefined
  >(undefined);
  const [itemSelected, setItemSelectedState] = useState<
    | {
        quizzer: number;
        team: number;
        thing: ThingSelectedType;
      }
    | undefined
  >(undefined);
  const setItemSelected = (
    value:
      | {
          quizzer: number;
          team: number;
          thing: ThingSelectedType;
        }
      | undefined
  ): void => {
    setTentativeTeamSelection(undefined);
    setItemSelectedState(value);
  };
  const [assigningErrors, setAssigningErrors] = useState<boolean>(false);
  const [assigningKError, setAssigningKError] = useState<boolean>(false);

  const swipeStatusDefault: Swiping = {
    sX: -1,
    sY: -1,
    eX: -1,
    eY: -1,
  };
  const [swipeStatus, setSwipeStatus] = useState<Swiping>(swipeStatusDefault);
  const [activeSwipe, setActiveSwipe] = useState<boolean>(false);

  const awardThing = (
    teamIndex: number,
    quizzerIndex: number,
    thing: "POINTS" | "ERRORS",
    errorType?: skTypes.ErrorType,
    kErrorType?: skTypes.KErrorType
  ) => {
    // Make sure the quizzer exists
    if (!teams[teamIndex] || !teams[teamIndex].quizzers[quizzerIndex]) return;

    // Give 20 points or error
    const newMoment: skTypes.Moment = {
      team: teamIndex,
      quizzerId: teams[teamIndex].quizzers[quizzerIndex].quizzerId,
      type: thing === points ? skTypes.tp : skTypes.error,
      errorType,
      kErrorType,
    };
    addMoment(newMoment);

    setItemSelected(undefined);
    setAssigningErrors(false);
    setReceivedCharacters(undefined);
    setUnknownFinalWord(false);
  };
  const onClickQuizzer = (
    teamIndex: number,
    quizzerIndex: number,
    thing: ThingSelectedType
  ) => {
    if (!quizEditable || watching) return;
    if (
      !itemSelected ||
      itemSelected.team !== teamIndex ||
      itemSelected.thing !== thing
    ) {
      setItemSelected({
        quizzer: quizzerIndex,
        team: teamIndex,
        thing: thing,
      });
      setAssigningErrors(false);
    } else {
      if (thing === quizzerName) {
        // Do the substitution
        setItemSelected(undefined);
        setAssigningErrors(false);
        const newTeams = [...teams];
        newTeams[teamIndex] = {
          ...newTeams[teamIndex],
          quizzers: [...newTeams[teamIndex].quizzers],
        };
        // Switch the two quizzers
        const temp: skTypes.Quizzer =
          newTeams[teamIndex].quizzers[quizzerIndex];
        newTeams[teamIndex].quizzers[quizzerIndex] =
          newTeams[teamIndex].quizzers[itemSelected.quizzer];
        newTeams[teamIndex].quizzers[itemSelected.quizzer] = temp;

        // Switch the captain, possibly
        if (newTeams[teamIndex].captian !== undefined) {
          if (newTeams[teamIndex].captian === quizzerIndex) {
            newTeams[teamIndex].captian = itemSelected.quizzer;
          } else if (newTeams[teamIndex].captian === itemSelected.quizzer) {
            newTeams[teamIndex].captian = quizzerIndex;
          }
          // Clear the captain if subbed
          const newCaptain = newTeams[teamIndex].captian;
          if (newCaptain && newCaptain >= 5)
            newTeams[teamIndex].captian = undefined;
        }
        dispatch(updateQuiz({ ...quiz, teams: newTeams }, user !== undefined));
      } else {
        if (itemSelected.quizzer !== quizzerIndex) {
          // Different quizzer on the same team
          setItemSelected({
            quizzer: quizzerIndex,
            team: teamIndex,
            thing: thing,
          });
          setAssigningErrors(false);
        } else {
          if (thing === points || !advanced)
            awardThing(
              teamIndex,
              quizzerIndex,
              thing === points ? points : errors
            );
          else setAssigningErrors(true);
        }
      }
    }
  };
  const isQuizzerEligable = (
    teamIndex: number,
    quizzerIndex: number
  ): boolean =>
    momentEditing !== undefined ||
    utils.isQuizzerEligable(boxScore, teamIndex, quizzerIndex);

  const getBackgroundColor = (
    teamIndex: number,
    quizzerIndex: number,
    thing: string
  ): string | undefined => {
    if (!itemSelected || (touchscreen && thing !== quizzerName))
      return undefined;
    if (
      itemSelected.team === teamIndex &&
      itemSelected.quizzer === quizzerIndex &&
      itemSelected.thing === thing
    )
      return colors.bqaDarkBlue;
    return undefined;
  };

  // Keeping track of material
  const booksOriginal: number[] = [];
  settings.selectedSKBooks.forEach((book: boolean, index: number) => {
    if (book) booksOriginal.push(index);
  });
  const books: Readonly<number[]> =
    booksOriginal.length > 0 ? booksOriginal : [2];

  const [ghostSelection, setGhostSelection] = useState<boolean>(false); // See the verse from the previous moment, but it's not really selected
  // These refer to what the user can see; you must subtract one to get the corresponding indecies
  const [selectedBook, setSelectedBook] = useState<number>(
    books.length > 1 ? 0 : books[0] + 1
  );
  const [selectedChapter, setSelectedChapter] = useState<number>(0);
  const [selectedVerse, setSelectedVerse] = useState<number>(0);
  const [selectedWords, setSelectedWords] = useState<string>("");
  const [receivedCharacters, setReceivedCharacters] = useState<
    number | undefined
  >(undefined);
  const [unknownFinalWord, setUnknownFinalWord] = useState(false);
  const [colonInRef, setColonInRef] = useState<boolean>(false);
  const selectedVerseObj = useMemo(() => {
    return selectedBook > 0 && selectedChapter > 0 && selectedVerse > 0
      ? material[selectedBook - 1].chapters[selectedChapter - 1][
          selectedVerse - 1
        ]
      : undefined;
  }, [selectedBook, selectedChapter, selectedVerse]);
  const { possible: possibleQuestions, impossible: impossibleQuestions } =
    useMemo(() => {
      if (!selectedVerseObj) return { possible: [], impossible: [] };
      const possible: string[] = [];
      const impossible: string[] = [];
      selectedVerseObj.questions.forEach((question: string) => {
        if (question.startsWith(selectedWords)) possible.push(question);
        else impossible.push(question);
      });
      return { possible, impossible };
    }, [selectedVerseObj, selectedWords]);

  const possibleWords = useMemo(() => {
    // Gets the words that could be the next word
    if (selectedWords.includes("?")) return [];
    const result: Record<string, number> = {}; // [word]: count
    possibleQuestions.forEach((question: string) => {
      const part = question.substring(selectedWords.length);
      const word = part.substring(0, part.indexOf(" ")).trim();
      if (word.length > 0 && !word.includes("(") && !word.includes("[")) {
        if (result[word]) result[word] += 1;
        else result[word] = 1;
      }
    });
    const sortedList = Object.keys(result)
      .map((key: string) => ({
        word: key,
        count: result[key],
      }))
      .sort((w1, w2) => w2.count - w1.count);
    if (selectedWords.length > 0) return sortedList;
    return [
      {
        word: "Quote?",
        count: 1,
      },
      ...sortedList,
    ];
  }, [selectedWords, possibleQuestions]);

  const clearReference = (): void => {
    setSelectedChapter(0);
    setColonInRef(false);
    setSelectedVerse(0);
    setSelectedWords("");
    if (books.length > 1) {
      setSelectedBook(0);
    }
    setGhostSelection(false);
    setReceivedCharacters(undefined);
    setUnknownFinalWord(false);
  };

  const onClickBookButton = (bookIndex: number) => {
    if (bookIndex < 0 || bookIndex > books.length - 1) return;
    if (ghostSelection) clearReference();
    setSelectedBook(books[bookIndex] + 1);
  };
  const onClickNumberPad = (num: number): void => {
    if (ghostSelection) {
      clearReference();
      setSelectedBook(books[0] + 1);
      if (num > material[books[0]].chapters.length) {
        return;
      } else {
        setSelectedChapter(num);
        return;
      }
    }
    if (colonInRef) {
      const newVerse = selectedVerse * 10 + num;
      if (
        newVerse >
        material[selectedBook - 1].chapters[selectedChapter - 1].length
      )
        return;
      setSelectedVerse(newVerse);
    } else {
      let addColon: boolean = false;
      const newChapter = selectedChapter * 10 + num;
      if (newChapter > material[selectedBook - 1].chapters.length) {
        if (newChapter >= 10) {
          addColon = true;
        } else {
          return;
        }
      }
      if (addColon) {
        setColonInRef(true);
        setSelectedVerse(num);
      } else {
        setSelectedChapter(newChapter);
      }
    }
  };
  const onClickBack = (): void => {
    if (ghostSelection) {
      clearReference();
      return;
    }
    // Clicking back on the number pad
    if (selectedWords.length > 0) {
      const removedPart = selectedWords.trim();
      setSelectedWords(
        removedPart.substring(0, removedPart.lastIndexOf(" ") + 1)
      );
      return;
    }
    if (selectedChapter === 0) {
      clearReference();
      return;
    }
    if (selectedVerse === 0) {
      if (colonInRef) {
        setColonInRef(false);
        return;
      } else {
        setSelectedChapter(Math.floor(selectedChapter / 10));
        return;
      }
    }
    setSelectedVerse(Math.floor(selectedVerse / 10));
  };
  const onClickColon = (): void => {
    if (!colonInRef && selectedChapter > 0) setColonInRef(true);
  };
  const getNumberPad = (): React.ReactElement[] => {
    const getNumberPadItem = (
      num: number,
      noMarginRight: boolean
    ): React.ReactElement => {
      return (
        <button
          className="numpad-button"
          style={{ marginRight: noMarginRight ? 0 : undefined }}
          key={num}
          onClick={(e) => {
            e.preventDefault();
            onClickNumberPad(num);
          }}
        >
          {num}
        </button>
      );
    };
    const result: React.ReactElement[] = [];
    // The 1-9 grid
    for (let i = 2; i >= 0; i--) {
      const arr = [1, 2, 3];
      result.push(
        <Row key={`numpad-${i}`}>
          {arr.map((num: number) => getNumberPadItem(i * 3 + num, num === 3))}
        </Row>
      );
    }
    result.push(
      <Row key="numpad-bottom">
        <button
          className="numpad-button"
          data-testid="sk-ref-back"
          onClick={
            !ghostSelection
              ? (e) => {
                  e.preventDefault();
                  onClickBack();
                }
              : undefined
          }
        >
          {"<"}
        </button>
        {getNumberPadItem(0, false)}
        <button
          className="numpad-button"
          style={{ marginRight: 0 }}
          onClick={
            !ghostSelection
              ? (e) => {
                  e.preventDefault();
                  onClickColon();
                }
              : undefined
          }
        >
          {":"}
        </button>
      </Row>
    );
    return result;
  };

  /**
   * Selectes a word in the list
   * @param num the number in the possibleWords array to choose
   */
  const onClickWord = (num: number): void => {
    if (num >= 0 && num < possibleWords.length) {
      setSelectedWords(`${selectedWords}${possibleWords[num].word} `);
    }
  };

  const clickThrowout = (): void => {
    // throwout, but add any info we can
    addMoment({
      type: skTypes.throwout,
      team: itemSelected?.team,
      quizzerId:
        itemSelected?.quizzer !== undefined && itemSelected?.team !== undefined
          ? teams[itemSelected.team]?.quizzers[itemSelected.quizzer]?.quizzerId
          : undefined,
      book: selectedBook - 1,
      chapter: selectedChapter - 1,
      verse: selectedVerse - 1,
    });
  };
  const clickNoQuestion = (): void => {
    addMoment({
      type: skTypes.noQuestion,
      book: selectedBook - 1,
      chapter: selectedChapter - 1,
      verse: selectedVerse - 1,
      question: selectedWords,
    });
  };

  const selectPreviousSelection = (moment: skTypes.Moment): void => {
    // Select what was previously selected
    setGhostSelection(false);
    if (books.length > 1)
      setSelectedBook(moment.book !== undefined ? moment.book + 1 : 0);
    setSelectedChapter(moment.chapter !== undefined ? moment.chapter + 1 : 0);
    setSelectedVerse(moment.verse !== undefined ? moment.verse + 1 : 0);
    setReceivedCharacters(moment.receivedCharacters);
    setUnknownFinalWord(false);
    setSelectedWords(moment.question || "");
  };
  const clickUndo = (): void => {
    if (!quiz || !quiz.moments.length || replaySettings) return;
    // Remove the moment
    const newMoments = [...quiz.moments];
    const oldMoment = newMoments.splice(newMoments.length - 1, 1)[0];
    const newQuiz = {
      ...quiz,
      moments: newMoments,
    };
    handler.runRequest(updateQuiz(newQuiz, user !== undefined));
    selectPreviousSelection(oldMoment);
  };

  const keyCapture = (e: KeyboardEvent) => {
    const modifier: boolean = e.shiftKey;
    const key: string = e.code;
    if (view !== "SK") {
      switch (view) {
        case "SHOW_TEAM":
          setView("SK");
          break;
      }
      return;
    }
    for (let i = 0; i < 10; i++) {
      if (hotkeys[("num" + i) as skTypes.SKHotkey] === key) {
        const num = i;
        // Number hotkey
        if (replaySettings) return;
        // the keypress was a number
        if (mouseInQs) {
          if (!selectedBook || (ghostSelection && books.length > 1)) {
            let bookIndex = num - 1;
            if (bookIndex === -1) bookIndex = 9;
            onClickBookButton(bookIndex);
            return;
          }
          if (selectedVerse > 0 && !ghostSelection && advanced) {
            if (
              possibleQuestions.length === 1 &&
              selectedWords.length > 0 &&
              possibleWords.length === 1 &&
              num === 2
            ) {
              setSelectedWords(possibleQuestions[0].split("?")[0] + "?");
            }
            if (
              modifier ||
              selectedVerse >= 10 ||
              selectedWords.length > 0 ||
              selectedVerse * 10 + num >
                material[selectedBook - 1].chapters[selectedChapter - 1].length
            ) {
              onClickWord(
                (num === 0 ? 10 : num) - (selectedWords.length === 0 ? 0 : 1)
              );
              return;
            }
          }
          onClickNumberPad(num);
          return;
        } else {
          if (boxScore.quizStatus === "ENDED") return;
          if (
            itemSelected &&
            itemSelected.quizzer >= 0 &&
            (assigningErrors || assigningKError)
          ) {
            if (num === 6 || num === 0) {
              // Cancel errors
              if (assigningErrors) {
                setAssigningErrors(false);
              } else {
                setAssigningKError(false);
                setAssigningErrors(true);
              }
              return;
            }
            let eType: skTypes.ErrorType | undefined;
            let kErrorType: skTypes.KErrorType | undefined;
            switch (num) {
              case 1:
                eType = skTypes.light;
                kErrorType = "WRONG_VERSE";
                break;
              case 2:
                eType = skTypes.split;
                kErrorType = "WRONG_WORD";
                break;
              case 3:
                eType = skTypes.ref;
                kErrorType = "QUESTION";
                break;
              case 4:
                eType = skTypes.fifty;
                kErrorType = "TIME";
                break;
              case 5:
                eType = skTypes.knowledge;
                kErrorType = "BAD_CALL";
                break;
            }
            if (assigningKError) eType = skTypes.knowledge;
            if (eType) {
              if (assigningErrors && eType === skTypes.knowledge) {
                setAssigningErrors(false);
                setAssigningKError(true);
              } else {
                awardThing(
                  itemSelected.team,
                  itemSelected.quizzer,
                  errors,
                  eType,
                  assigningKError ? kErrorType : undefined
                );
              }
              return;
            }
          }
          if (tentativeTeamSelection === undefined) {
            if (num > teams.length || num <= 0) return;
            setTentativeTeamSelection(num - 1);
            return;
          }
          if (tentativeTeamSelection !== undefined) {
            if (num > teams[tentativeTeamSelection].quizzers.length) return;
            onClickQuizzer(tentativeTeamSelection, num - 1, quizzerName);
            return;
          }
        }
        return;
      }
    }

    if (!modifier) {
      if (
        [
          hotkeys.goUp,
          hotkeys.goDown,
          hotkeys.goRight,
          hotkeys.goLeft,
        ].includes(key)
      ) {
        if (!itemSelected || !itemSelected.thing) return;
        switch (key) {
          case hotkeys.goLeft:
          case hotkeys.goRight:
            const newItemSelected = {
              ...itemSelected,
            };
            // Switching teams
            let foundMovement: boolean = false;
            if (itemSelected.thing === quizzerName && key === hotkeys.goLeft) {
              newItemSelected.team =
                (itemSelected.team - 1 + teams.length) % teams.length;
              newItemSelected.thing = errors;
              foundMovement = true;
            }
            if (itemSelected.thing === errors && key === hotkeys.goRight) {
              newItemSelected.team =
                (itemSelected.team + 1 + teams.length) % teams.length;
              newItemSelected.thing = quizzerName;
              foundMovement = true;
            }
            if (!foundMovement) {
              // Staying on the same team; moving either from or to points
              newItemSelected.thing =
                itemSelected.thing === points
                  ? key === hotkeys.goLeft
                    ? quizzerName
                    : errors
                  : points;
            }
            setItemSelected(newItemSelected);
            return;
          case hotkeys.goUp:
          case hotkeys.goDown:
            const quizzerCount = teams[itemSelected.team].quizzers.length;
            setItemSelected({
              team: itemSelected.team,
              quizzer:
                (itemSelected.quizzer +
                  (key === hotkeys.goDown ? 1 : -1) +
                  quizzerCount) %
                quizzerCount,
              thing: itemSelected.thing,
            });
            return;
        }
      }

      switch (key) {
        case hotkeys.toggleSelection:
          toggleMouseInQs();
          return;
        case hotkeys.assign:
          if (itemSelected?.thing)
            onClickQuizzer(
              itemSelected.team,
              itemSelected.quizzer,
              itemSelected.thing
            );
          return;
        case hotkeys.colon:
          onClickColon();
          return;
        case hotkeys.goBack:
          if (mouseInQs || !itemSelected) onClickBack();
          else setItemSelected(undefined);
          return;
        case hotkeys.clearAll:
          clearReference();
          setItemSelected(undefined);
          setAssigningErrors(false);
          return;
        case hotkeys.quote:
          if (advanced && selectedVerse > 0 && selectedWords === "")
            onClickWord(0);
          return;
        case hotkeys.showTeam:
          if (!modifier) {
            setView("SHOW_TEAM");
          }
          return;
        case hotkeys.runningScore:
          setView("Running Score");
          return;
        case hotkeys.pbp:
          setView("PBP");
          return;
        case hotkeys.winProbabilities:
          setView("Win Probabilities");
          return;
      }
    } else {
      if (watching) return;
      switch (key) {
        case hotkeys.throwout:
          if (modifier) {
            clickThrowout();
            e.preventDefault();
          }
          return;
        case hotkeys.noQuestion:
          if (modifier) {
            e.preventDefault();
            clickNoQuestion();
          }
          return;
        case hotkeys.halftime:
          if (boxScore.quizStatus === skTypes.firstHalf) {
            addMoment({
              type: skTypes.halftime,
            });
          }
          return;
        case hotkeys.overtime:
          if (boxScore.quizStatus !== skTypes.firstHalf) {
            addMoment({
              type: skTypes.startOvertime,
            });
          }
          return;
        case hotkeys.undo:
          clickUndo();
          return;
        case hotkeys.reverseTeams:
          setReverseTeams(!reverseTeams);
      }
    }
  };
  const checkSpace = (e: KeyboardEvent) => {
    if (["space", " ", "arrow"].includes(e.key) && e.target === document.body) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    document.addEventListener("keyup", keyCapture);
    document.addEventListener("keydown", checkSpace);
    return () => {
      document.removeEventListener("keyup", keyCapture);
      document.removeEventListener("keydown", checkSpace);
    };
  });

  const getMomentDescription = (): string => {
    if (!quiz) return "";
    let momentIndex: number =
      momentEditing !== undefined ? momentEditing : quiz.moments.length - 1;
    let result: string = "";
    if (replaySettings) {
      let neededQuestions = replaySettings.selectedQuestion;
      for (let i = 0; i < quiz.moments.length; i++) {
        if (utils.isQuestion(quiz.moments[i])) neededQuestions -= 1;
        if (neededQuestions === 0) {
          momentIndex = i;
          break;
        }
      }
    }
    const moment = quiz.moments[momentIndex];
    if (moment.quizzerId) {
      if (moment.book !== undefined) {
        result += material[moment.book].bookName + " ";
      }
      if (moment.chapter !== undefined) {
        result += moment.chapter + 1;
      }
      if (moment.verse !== undefined) {
        result += ":" + (moment.verse + 1);
      }
      if (moment.chapter !== undefined) {
        result += " ";
      }
    }
    if (moment.team !== undefined && teams[moment.team] !== undefined) {
      if (moment.quizzerId) {
        result = result.concat(
          (teams[moment.team].quizzers.find(
            (quizzer: skTypes.Quizzer) => quizzer.quizzerId === moment.quizzerId
          )?.quizzerName || "") + " "
        );
      } else {
        result = result.concat(teams[moment.team].teamName + " ");
      }
    }
    result = result.concat(
      (moment.errorType && skTypes.errorDescription[moment.errorType]) ||
        skTypes.momentTypeDescriptions[moment.type]
    );
    if (moment.kErrorType)
      result = `${result} (${skTypes.kErrorDescription[moment.kErrorType]})`;
    return result;
  };

  const getTeamNameDisplay = (
    team: skTypes.TeamBoxScore,
    teamIndex: number
  ): React.ReactNode => {
    const teamName = team.teamName;
    let part1 = "";
    let part2 = "";
    const hyphenSpot = teamName.indexOf(" - ");
    if (hyphenSpot >= 0) {
      part1 = teamName.substring(0, hyphenSpot);
      part2 = teamName.substring(hyphenSpot);
    } else {
      part1 = teamName;
    }
    if (boxScore.quizStatus === "ENDED")
      part1 = `${boxScore.finalPlacements[teamIndex]}. ${part1}`;
    return (
      <>
        <span
          className="no-wrap-text"
          style={{ display: "inline-block", maxWidth: teamDivWidth }}
        >
          <span
            style={{
              fontSize: 24,
              color:
                tentativeTeamSelection === teamIndex
                  ? colors.bqaBlue
                  : undefined,
              textDecoration:
                !team.eligable && boxScore.quizStatus !== "ENDED"
                  ? "line-through"
                  : undefined,
            }}
          >
            {part1}
          </span>
          {part2 && <span style={{ fontSize: 16 }}>{part2}</span>}
        </span>
        {team.coachesTimeoutAvailable && (
          <sup
            style={{
              fontSize: 12,
              color: "grey",
              marginLeft: 4,
            }}
            data-testid={`sk-c-timeout-${teamIndex}`}
          >
            C
          </sup>
        )}
      </>
    );
  };

  const setView = (view: string) => {
    navigate(location.pathname, {
      state: {
        ...(location.state || {}),
        skView: view,
      },
    });
  };

  // Win probabilities for the current moment
  const currentWinProbabilities = useMemo(() => {
    try {
      if (advanced && quiz && boxScore.quizStatus !== skTypes.ended) {
        const result = statsUtils.getWinProbability(
          boxScore,
          statsUtils.getEstimatedQuestionsRemaining(
            statsUtils.getQuestionsByPeriod(quiz.moments)
          ) +
            (replaySettings
              ? originalBoxScore.totalQuestions -
                replaySettings.selectedQuestion
              : 0),
          true,
          replaySettings
        );
        return result;
      }
      return [];
    } catch {
      return [];
    }
  }, [quiz?.moments, replaySettings]);

  /**
   * begins the process to edit one of the moments of the quiz
   * @param momentIndex the moment to edit
   */
  const startEditingMoment = (momentIndex: number) => {
    setMomentEditing(momentIndex);
    if (quiz) {
      const oldMoment = quiz.moments[momentIndex];
      selectPreviousSelection(oldMoment);
    }
    setReplaySettings(undefined);
  };

  if (view === "SHOW_TEAM")
    return (
      <ShowTeam
        boxScore={boxScore}
        teams={teams}
        onClose={() => setView("SK")}
        reverse={reverseTeams}
      />
    );
  if (view === "PBP")
    return (
      <PlayByPlay
        moments={quiz?.moments || []}
        teams={teams}
        books={books}
        onClose={() => setView("SK")}
        onChangeMoments={(newMoments: skTypes.Moment[]) => {
          if (!quiz) return;
          const newQuiz: skTypes.Quiz = {
            ...quiz,
            moments: newMoments,
          };
          handler.runRequest(updateQuiz(newQuiz, user !== undefined));
        }}
        editable={!watching && boxScore.quizStatus !== "ENDED"}
        isPractice={quiz?.isPractice}
        onEdit={(momentIndex) => {
          startEditingMoment(momentIndex);
          setView("SK");
        }}
      />
    );
  if (view === "Running Score")
    return (
      <RunningScore
        moments={quiz?.moments || []}
        teams={teams}
        onClose={() => setView("SK")}
      />
    );
  if (view === "Win Probabilities")
    return (
      <WinProbabilities
        moments={quiz?.moments || []}
        teams={teams}
        onClose={() => setView("SK")}
      />
    );

  if (view === "Edit Details" && quiz !== undefined)
    return (
      <EditDetails
        quiz={quiz}
        quizmasterList={seasonProvider.record?.quizmasters || []}
        onSubmit={(quiz: skTypes.Quiz) => {
          handler.runRequest(updateQuiz(quiz, user !== undefined));
          setView("SK");
        }}
        onDelete={() => {
          handler.runRequest(
            deleteQuiz(quiz.quizId, eventId, seasonId, user !== undefined),
            "Deleting the quiz...",
            "Quiz deleted!",
            "DELETE"
          );
        }}
      />
    );

  const getTweetText = (): string => {
    if (!quiz) return "";
    const space: string = "%20";
    const newLine: string = "%0D%0A";
    let result: string = `${
      quiz.quizName || `Round ${quiz.round}, Site ${quiz.site}`
    }${
      boxScore.quizStatus === skTypes.overtime ? " (Overtime)" : ""
    }${newLine}${newLine}`;
    boxScore.teams.forEach((team: skTypes.TeamBoxScore, index) => {
      const placePart =
        boxScore.finalPlacements?.[index] > 0
          ? boxScore.finalPlacements?.[index] + ". "
          : "";
      result = `${result}${placePart}${teams[index].teamName.replace(
        / /g,
        space
      )}:${space}${team.points}${newLine}`;
    });
    result = `${result}${newLine}From @bqAcademy`;
    return result;
  };

  const pageMinWidth: number = 800;
  const teamDivWidth = `max(calc(${100 / Math.max(teams.length, 2)}% - 4px), ${
    pageMinWidth / teams.length - 4
  }px)`;
  const swipeLength: number =
    touchscreen && swipeStatus && itemSelected
      ? Math.abs(swipeStatus.eX - swipeStatus.sX)
      : 0;

  const pointerDown = (
    e: TouchEvent<HTMLDivElement>,
    team: number,
    quizzer: number
  ) => {
    if (!touchscreen || !quizEditable || activeSwipe) return;

    // @ts-ignore if we didn't click on a box score item (namely, the dropdown)
    if (!e.target.className.startsWith("box-score-item")) return;
    // For touchscreen: initialize giving 20 points or an error, depending on direction of swipe
    if (itemSelected && itemSelected.team === team) {
      // do a substitution
      onClickQuizzer(team, quizzer, quizzerName);
      return;
    }
    setSwipeStatus({
      sX: e.touches[0].clientX,
      eX: e.touches[0].clientX,
      sY: e.touches[0].clientY,
      eY: e.touches[0].clientY,
    });
    setActiveSwipe(true);

    setItemSelected({ team, quizzer, thing: "" });
  };
  const pointerUp = () => {
    if (!touchscreen || !quizEditable) return;
    setSwipeStatus(swipeStatusDefault);
    if (itemSelected && activeSwipe) {
      if (
        Math.abs(swipeStatus.eX - swipeStatus.sX) > swiping.swipeCutoff &&
        utils.isQuizzerEligable(
          boxScore,
          itemSelected.team,
          itemSelected.quizzer
        )
      ) {
        // Give 20 points or error
        if (swipeStatus.eX > swipeStatus.sX) {
          // 20 points
          if (
            utils.isQuizzerEligable(
              boxScore,
              itemSelected.team,
              itemSelected.quizzer
            )
          )
            awardThing(itemSelected.team, itemSelected.quizzer, points);
          setItemSelected(undefined);
        } else {
          // error
          if (!advanced) {
            awardThing(itemSelected.team, itemSelected.quizzer, errors);
          } else {
            setAssigningErrors(true);
            setItemSelected({
              team: itemSelected.team,
              quizzer: itemSelected.quizzer,
              thing: errors,
            });
          }
        }
      } else if (swipeLength < swiping.tapCutoff) {
        onClickQuizzer(itemSelected.team, itemSelected.quizzer, quizzerName);
      } else {
        setItemSelected(undefined);
      }
    }
    setActiveSwipe(false);
  };

  const getAllTeamDetails = () => {
    const result = boxScore.teams.map(
      (team: skTypes.TeamBoxScore, index: number) => getTeamDetails(team, index)
    );
    if (!reverseTeams) return result;
    return result.reverse();
  };
  const getTeamDetails = (
    team: skTypes.TeamBoxScore,
    index: number
  ): React.ReactElement => {
    const addReplayRow = (key: "correctAnswers" | "errors") => {
      if (!replaySettings) return null;
      return (
        <NumberInput
          caption={`${
            key === "correctAnswers" ? "Correct Answers" : "Errors"
          }:`}
          value={replaySettings.teams[index][key]}
          onChange={(value) => {
            const resultValue = value;
            if (resultValue < 0) return;
            const newSettings = {
              ...replaySettings,
            };
            newSettings.teams[index][key] = resultValue;
            setReplaySettings(newSettings);
          }}
        />
      );
    };

    const getPresetLineups = (teamId: string): skTypes.Lineup[] => {
      const season = utils.getLocalSeason(seasonId);
      if (!season) return [];
      const thisTeam = season.teams.find((t) => t.teamId === teamId);
      return thisTeam?.presetLineups || [];
    };
    return (
      <div
        key={team.teamId}
        style={{
          minWidth: teamDivWidth,
          maxWidth: teamDivWidth,
          padding: 10,
          paddingBottom: 40,
          marginBottom: -35,
          overflow: "hidden",
        }}
      >
        {!quiz.isPractice && (
          <div style={{ fontSize: 24, marginBottom: 10, position: "relative" }}>
            <DropdownMenu
              menuEnabled={quizEditable}
              items={[
                ...(quiz.moments.length === 0
                  ? getPresetLineups(teams[index].teamId).map(
                      (lineup: skTypes.Lineup) => ({
                        key: lineup.lineupName,
                        onClick: () => {
                          const resultTeams = [...teams];
                          const quizzerList: skTypes.Quizzer[] = [];
                          // Add quizzers in the lineup to the quizzer array
                          lineup.quizzerIdList.forEach((quizzerId: string) => {
                            teams[index].quizzers.forEach(
                              (quizzer: skTypes.Quizzer) => {
                                if (quizzer.quizzerId === quizzerId) {
                                  quizzerList.push(quizzer);
                                }
                              }
                            );
                          });
                          // Add quizzers who weren't in the lineup at the end
                          teams[index].quizzers.forEach(
                            (quizzer: skTypes.Quizzer) => {
                              if (
                                !lineup.quizzerIdList.includes(
                                  quizzer.quizzerId
                                )
                              )
                                quizzerList.push(quizzer);
                            }
                          );
                          resultTeams[index] = {
                            ...resultTeams[index],
                            quizzers: quizzerList,
                            captian: lineup.captain,
                          };
                          const resultQuiz = {
                            ...quiz,
                            teams: resultTeams,
                          };
                          handler.runRequest(
                            updateQuiz(resultQuiz, user !== undefined)
                          );
                        },
                        title: `Lineup: ${lineup.lineupName}`,
                      })
                    )
                  : []),
                {
                  title: "Coach's Timeout",
                  onClick: () => {
                    addMoment({
                      team: index,
                      type: skTypes.ct,
                    });
                  },
                  visible: team.coachesTimeoutAvailable,
                },
                {
                  title: "Technical Foul",
                  onClick: () => {
                    addMoment({
                      team: index,
                      type: skTypes.tf,
                    });
                  },
                },
              ]}
              id={`sk-team-menu-${index}`}
              style={{
                margin: 0,
                textAlign: "left",
              }}
            >
              {getTeamNameDisplay(team, index)}
            </DropdownMenu>
            {currentWinProbabilities[index] !== undefined && (
              <span
                style={{
                  position: "absolute",
                  right: 5,
                  top: 10,
                  fontSize: 12,
                  color: "grey",
                }}
              >
                {`${currentWinProbabilities[index].totalProb.toFixed(
                  2
                )}/${currentWinProbabilities[index].firstProb.toFixed(2)}`}
              </span>
            )}
          </div>
        )}
        {team.quizzers.map(
          (quizzer: skTypes.QuizzerBoxScore, quizzerIndex: number) => {
            const thisQuizzerTouchSelected: boolean =
              itemSelected !== undefined &&
              itemSelected.team === index &&
              itemSelected.quizzer === quizzerIndex;
            return (
              <React.Fragment key={quizzer.quizzerId}>
                <div data-testid={`sk-team-${index}-${quizzerIndex}`}>
                  {itemSelected !== undefined &&
                  thisQuizzerTouchSelected &&
                  (assigningErrors || assigningKError) ? (
                    <div
                      style={{
                        display: "flex",
                        height: touchscreen ? 40 : 26,
                        minWidth: "100%",
                        maxWidth: "100%",
                        marginBottom: 4,
                        touchAction: watching ? "" : "none",
                      }}
                    >
                      {assigningErrors
                        ? (
                            Object.keys(
                              skTypes.errorTypeButtonText
                            ) as skTypes.ErrorType[]
                          ).map((errorType) => (
                            <button
                              key={errorType}
                              className="error-choice-button"
                              onClick={() => {
                                if (errorType === "K") {
                                  setAssigningKError(true);
                                  setAssigningErrors(false);
                                } else {
                                  awardThing(
                                    itemSelected.team,
                                    itemSelected.quizzer,
                                    errors,
                                    errorType
                                  );
                                }
                              }}
                            >
                              {skTypes.errorTypeButtonText[errorType]}
                            </button>
                          ))
                        : (
                            Object.keys(
                              skTypes.kErrorDescription
                            ) as skTypes.KErrorType[]
                          ).map((kErrorType) => (
                            <button
                              className="error-choice-button"
                              key={kErrorType}
                              onClick={() =>
                                awardThing(
                                  itemSelected.team,
                                  itemSelected.quizzer,
                                  errors,
                                  skTypes.knowledge,
                                  kErrorType
                                )
                              }
                            >
                              {skTypes.kErrorTypeButtonText[kErrorType]}
                            </button>
                          ))}
                      <button
                        className="error-choice-button"
                        onClick={() => {
                          if (assigningKError) {
                            setAssigningErrors(true);
                            setAssigningKError(false);
                          } else {
                            setAssigningErrors(false);
                            if (touchscreen) setItemSelected(undefined);
                          }
                        }}
                      >
                        {assigningKError ? "Back" : "Cancel"}
                      </button>
                    </div>
                  ) : (
                    <div
                      style={{
                        marginLeft:
                          thisQuizzerTouchSelected &&
                          swipeStatus.eX < swipeStatus.sX
                            ? -swipeLength
                            : 0,
                        maxWidth: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          height: 26,
                          marginBottom: 4,
                          touchAction: watching ? "" : "none",
                        }}
                        onTouchStart={(e) =>
                          pointerDown(e, index, quizzerIndex)
                        }
                      >
                        {touchscreen &&
                          activeSwipe &&
                          itemSelected &&
                          itemSelected.team === index &&
                          itemSelected.quizzer === quizzerIndex &&
                          swipeStatus.eX > swipeStatus.sX &&
                          !itemSelected.thing && (
                            <div
                              style={{
                                height: "100%",
                                backgroundColor:
                                  swipeStatus.eX - swiping.swipeCutoff >
                                  swipeStatus.sX
                                    ? "darkgreen"
                                    : "lightgreen",
                                minWidth: swipeLength,
                                maxWidth: swipeLength,
                              }}
                            />
                          )}
                        <div
                          style={{
                            minWidth: `${quizzerSeatWidth}%`,
                            maxWidth: `${quizzerSeatWidth}%`,
                          }}
                        >
                          <DropdownMenu
                            id={`sk-quizzer-dd-${index}-${quizzerIndex}`}
                            className="seat-display"
                            style={{
                              backgroundColor:
                                colonInRef &&
                                settings.skAdvanced &&
                                !ghostSelection &&
                                materialKnown[quizzer.quizzerId]?.books[
                                  selectedBook - 1
                                ]?.has(selectedChapter - 1)
                                  ? "#555555"
                                  : "transparent",
                            }}
                            menuEnabled={quizEditable}
                            items={[
                              {
                                title: "Foul",
                                onClick: () => {
                                  addMoment({
                                    type: skTypes.foul,
                                    team: index,
                                    quizzerId: quizzer.quizzerId,
                                  });
                                },
                              },
                              {
                                title: `Sub ${
                                  subbedOut.includes(quizzer.quizzerId)
                                    ? "In"
                                    : "Out"
                                }`,
                                onClick: () => {
                                  const subIndex = subbedOut.indexOf(
                                    quizzer.quizzerId
                                  );
                                  const newSubbedOut = [...subbedOut];
                                  if (subIndex === -1) {
                                    newSubbedOut.push(quizzer.quizzerId);
                                  } else {
                                    newSubbedOut.splice(subIndex, 1);
                                  }
                                  setSubbedOut(newSubbedOut);
                                  if (team.captain === subIndex) {
                                    // Deselect as captain
                                    const newTeams = [...teams];
                                    newTeams[index] = {
                                      ...newTeams[index],
                                      captian: undefined,
                                    };
                                    const newQuiz = {
                                      ...quiz,
                                      teams: newTeams,
                                    };
                                    handler.runRequest(
                                      updateQuiz(newQuiz, user !== undefined)
                                    );
                                  }
                                },
                              },
                              {
                                title: "Set Captain",
                                onClick: () => {
                                  const newTeams = [...teams];
                                  newTeams[index] = {
                                    ...newTeams[index],
                                    captian: quizzerIndex,
                                  };
                                  const newQuiz = {
                                    ...quiz,
                                    teams: newTeams,
                                  };
                                  handler.runRequest(
                                    updateQuiz(newQuiz, user !== undefined)
                                  );
                                },
                                visible:
                                  team.captain !== quizzerIndex &&
                                  quizzerIndex < 5 &&
                                  quizzer.eligable,
                              },
                              {
                                title: "Absent from Quiz",
                                onClick: () => {
                                  addMoment({
                                    type: skTypes.absent,
                                    team: index,
                                    quizzerId: quizzer.quizzerId,
                                  });
                                },
                              },
                              {
                                title: "Technical Foul",
                                onClick: () => {
                                  addMoment({
                                    type: skTypes.tf,
                                    team: index,
                                    quizzerId: quizzer.quizzerId,
                                  });
                                },
                              },
                            ]}
                          >
                            <div
                              style={{
                                margin: 0,
                                fontSize: 16,
                                color:
                                  quizzerIndex < 5 &&
                                  quizzer.eligable &&
                                  !quiz.isPractice &&
                                  (team.captain === undefined ||
                                    team.captain === quizzerIndex) &&
                                  boxScore.quizStatus !== "ENDED"
                                    ? "red"
                                    : undefined,
                              }}
                            >
                              {`${
                                quizzerIndex < 5 &&
                                !subbedOut.includes(quizzer.quizzerId)
                                  ? quizzerIndex + 1
                                  : "S"
                              }.`}
                            </div>
                          </DropdownMenu>
                        </div>
                        <div
                          className="box-score-item"
                          data-testid="q-name"
                          style={{
                            minWidth: `${quizzerNameWidth}%`,
                            maxWidth: `${quizzerNameWidth}%`,
                            backgroundColor: getBackgroundColor(
                              index,
                              quizzerIndex,
                              quizzerName
                            ),
                            color:
                              boxScore.quizStatus !== "ENDED" &&
                              quizzer.points === 0 &&
                              team.quizzersAnswering >= 4 &&
                              isQuizzerEligable(index, quizzerIndex)
                                ? "crimson"
                                : undefined,
                            textDecoration:
                              quizzer.eligable ||
                              boxScore.quizStatus === skTypes.ended
                                ? undefined
                                : "line-through",
                          }}
                          onClick={
                            !touchscreen
                              ? () => {
                                  onClickQuizzer(
                                    index,
                                    quizzerIndex,
                                    quizzerName
                                  );
                                }
                              : undefined
                          }
                        >
                          {quizzer.quizzerName}
                        </div>
                        <div
                          className="box-score-item box-score-points-errors"
                          data-testid="pts"
                          style={{
                            minWidth: `${quizzerPointsWidth}%`,
                            maxWidth: `${quizzerPointsWidth}%`,
                            backgroundColor: getBackgroundColor(
                              index,
                              quizzerIndex,
                              points
                            ),
                          }}
                          onClick={
                            !touchscreen
                              ? () => {
                                  if (
                                    isQuizzerEligable(index, quizzerIndex) &&
                                    !touchscreen
                                  )
                                    onClickQuizzer(index, quizzerIndex, points);
                                }
                              : undefined
                          }
                        >
                          {quizzer.points}
                        </div>
                        <div
                          className="box-score-item box-score-points-errors"
                          data-testid="errs"
                          style={{
                            color: quizzer.foul ? "orange" : undefined,
                            minWidth: `${quizzerErrorsWidth}%`,
                            maxWidth: `${quizzerErrorsWidth}%`,
                            backgroundColor: getBackgroundColor(
                              index,
                              quizzerIndex,
                              errors
                            ),
                            textAlign: "center",
                          }}
                          onClick={
                            !touchscreen
                              ? () => {
                                  if (
                                    isQuizzerEligable(index, quizzerIndex) &&
                                    !touchscreen
                                  )
                                    onClickQuizzer(index, quizzerIndex, errors);
                                }
                              : undefined
                          }
                        >
                          {quizzer.errors}
                        </div>
                        {touchscreen &&
                          itemSelected &&
                          itemSelected.team === index &&
                          itemSelected.quizzer === quizzerIndex &&
                          swipeStatus.eX < swipeStatus.sX &&
                          !itemSelected.thing && (
                            <div
                              style={{
                                height: "100%",
                                backgroundColor:
                                  swipeStatus.eX + swiping.swipeCutoff <
                                  swipeStatus.sX
                                    ? "darkred"
                                    : "pink",
                                minWidth: swipeLength,
                                maxWidth: swipeLength,
                              }}
                            />
                          )}
                      </div>
                    </div>
                  )}
                </div>
                {!quiz?.isPractice &&
                  (quizzerIndex === 4 ||
                    (team.quizzers.length === quizzerIndex + 1 &&
                      quizzerIndex < 4)) && (
                    // Line for break before substitutes or scores
                    <hr
                      className="separator-line-small"
                      style={{ marginBottom: 4 }}
                    />
                  )}
              </React.Fragment>
            );
          }
        )}
        {!quiz.isPractice && (
          <div
            style={{
              display: "flex",
              padding: 0,
              marginTop: -3,
              marginLeft: 0,
            }}
          >
            <div
              style={{
                marginLeft: `${quizzerSeatWidth + quizzerNameWidth}%`,
                minWidth: `${quizzerPointsWidth}%`,
                maxWidth: `${quizzerPointsWidth}%`,
                textAlign: "center",
                fontSize: 28,
                padding: 0,
              }}
            >
              {team.points}
            </div>
            <div
              className={`errors-container ${
                team.errors >= 3
                  ? team.errors >= 6
                    ? "high-errors"
                    : "middle-errors"
                  : ""
              }`}
              style={{
                minWidth: `${quizzerErrorsWidth}%`,
                maxWidth: `${quizzerErrorsWidth}%`,
              }}
            >
              {team.errors}
            </div>
          </div>
        )}
        {replaySettings && (
          <div
            style={{
              marginTop: 5 + Math.max(0, (7 - team.quizzers.length) * 30),
            }}
          >
            <Check
              checked={replaySettings.teams[index].setJumps}
              onClick={(checked: boolean) => {
                const newSettings = { ...replaySettings };
                newSettings.teams[index] = {
                  setJumps: checked,
                  correctAnswers: 0,
                  errors: 0,
                };
                setReplaySettings(newSettings);
              }}
            >
              Set Remaining Jumps
            </Check>
            {replaySettings.teams[index].setJumps && (
              <>
                {addReplayRow("correctAnswers")}
                {addReplayRow("errors")}
              </>
            )}
          </div>
        )}
      </div>
    );
  };
  const getLastMomentRow = () => {
    return (
      <p
        style={{ marginBottom: 10, marginTop: 0, marginLeft: 5, minHeight: 24 }}
      >
        {quiz &&
          quiz.moments.length > 0 &&
          (!replaySettings || replaySettings.selectedQuestion > 0) && (
            <>
              {momentEditing !== undefined && (
                <span style={{ fontWeight: "bold", color: "red" }}>
                  {"EDITING "}
                </span>
              )}
              <span data-testid="sk-last-moment">{getMomentDescription()}</span>
              {momentEditing !== undefined && (
                <button
                  className="link"
                  key="cancel-edit"
                  style={{ marginLeft: 15 }}
                  onClick={() => {
                    setMomentEditing(undefined);
                    setGhostSelection(true);
                  }}
                >
                  Cancel Edit
                </button>
              )}
              {momentEditing === undefined && !replaySettings && !watching && (
                <>
                  <button
                    className="link"
                    style={{ marginLeft: 15 }}
                    onClick={clickUndo}
                    key={`undo-${quiz.moments.length}`}
                  >
                    Undo
                  </button>
                  <button
                    className="link"
                    style={{ marginLeft: 15 }}
                    onClick={() => {
                      if (quiz) startEditingMoment(quiz.moments.length - 1);
                    }}
                    key="edit-last"
                  >
                    Edit
                  </button>
                </>
              )}
            </>
          )}
        {reverseTeams && (
          <button
            className="link"
            onClick={() => setReverseTeams(false)}
            style={{ marginLeft: 10 }}
          >
            De-Reverse Teams
          </button>
        )}
      </p>
    );
  };
  const getHeaderRow = () => {
    if (teamViewing !== undefined)
      return (
        <Row style={{ padding: 5 }}>
          <button className="link" onClick={() => setTeamViewing(undefined)}>
            Go Back
          </button>
        </Row>
      );
    return (
      <Row style={{ padding: 5, position: "relative" }}>
        <button
          className="link"
          style={{ marginLeft: 10 }}
          onClick={() => {
            navigate(watching ? "/watchquiz" : `/sk/${seasonId}/${eventId}`);
          }}
        >
          Back
        </button>
        <DropdownMenu
          className="link"
          style={{
            display: !quizEditable ? "none" : undefined,
            marginLeft: 15,
            marginTop: 0.5,
          }}
          items={[
            {
              title: "Halftime",
              onClick: () => {
                addMoment({
                  type: skTypes.halftime,
                });
              },
              visible: boxScore.quizStatus === skTypes.firstHalf,
            },
            {
              title: "Overtime",
              onClick: () => {
                addMoment({
                  type: skTypes.startOvertime,
                });
              },
              visible: boxScore.quizStatus !== skTypes.firstHalf,
            },
            {
              title: "Quiz Pause",
              onClick: () => {
                addMoment({
                  type: skTypes.quizPause,
                });
              },
            },
            {
              title: "End Quiz",
              onClick: () => {
                addMoment({
                  type: skTypes.endQuiz,
                });
                if (quiz.quizmaster) setView("QM_RATING");
              },
            },
          ]}
          id="sk-quiz-menu"
        >
          Quiz
        </DropdownMenu>
        {!watching && (
          <button
            className="link"
            style={{ marginLeft: 15 }}
            onClick={() => setView("Edit Details")}
            data-testid="sk-edit-details"
          >
            Edit Quiz Details
          </button>
        )}
        {watching && (
          <button className="link" style={{ marginLeft: 15 }} onClick={refresh}>
            Refresh
          </button>
        )}
        {!quiz.isPractice && (
          <button
            className="link"
            style={{ marginLeft: 15 }}
            onClick={() => setView("SHOW_TEAM")}
            aria-label="Show Team"
            data-testid="sk-show-team"
          >
            <EyeIcon width={20} height={20} />
          </button>
        )}
        {boxScore.quizStatus === "ENDED" && !!quiz.quizmaster && (
          <button
            className="link"
            style={{ marginLeft: 15 }}
            onClick={() => setView("QM_RATING")}
          >
            QM Ratings
          </button>
        )}
        <DropdownMenu
          className="link"
          style={{
            marginLeft: 15,
          }}
          items={[
            {
              title: "Play-By-Play",
              onClick: () => setView("PBP"),
            },
            {
              title: "Running Score Graph",
              onClick: () => setView("Running Score"),
              visible: !quiz.isPractice,
            },
            {
              title: "Win Probability Graph",
              onClick: () => setView("Win Probabilities"),
              visible: !quiz.isPractice && settings.skAdvanced,
            },
            {
              title: replaySettings ? "Stop Replaying" : "Replay Quiz",
              onClick: () => {
                if (!replaySettings) {
                  setReplaySettings({
                    selectedQuestion: 0,
                    teams: teams.map((team: skTypes.Team) => ({
                      correctAnswers: 0,
                      errors: 0,
                      setJumps: false,
                    })),
                  });
                } else {
                  setReplaySettings(undefined);
                }
              },
              visible: !quiz.isPractice,
            },
            {
              title: !reverseTeams ? "Reverse Teams" : "De-Reverse Teams",
              onClick: () => setReverseTeams(!reverseTeams),
              visible: !quiz.isPractice,
            },
            {
              title: "Quizzer Stats",
              onClick: () => {
                const settings =
                  statsUtils.getEmptyStatsSettings(skIncludeOvertime);
                settings.quizIds = new Set<string>([quiz.quizId]);
                navigate(`/sk/${seasonId}/quizzerstats`, {
                  state: {
                    settings,
                    fromQuiz: location.pathname,
                  },
                });
              },
            },
            {
              title: "Team Stats",
              onClick: () => {
                const settings =
                  statsUtils.getEmptyStatsSettings(skIncludeOvertime);
                settings.quizIds = new Set<string>([quiz.quizId]);
                navigate(`/sk/${seasonId}/teamstats`, {
                  state: {
                    settings,
                    fromQuiz: location.pathname,
                  },
                });
              },
            },
          ]}
          id="sk-tools-menu"
        >
          <WrenchIcon
            width={20}
            height={20}
            aria-label="Tools"
            data-testid="sk-tools-menu"
          />
        </DropdownMenu>
        {!quiz.isPractice && boxScore.quizStatus === "ENDED" && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: 15 }}
            href={`https://twitter.com/intent/tweet?text=${getTweetText()}`}
          >
            Tweet
          </a>
        )}
        {watching && (
          <span style={{ color: "red", marginLeft: 15 }}>
            {broadcastError
              ? "Quiz Error"
              : `LIVE - Refreshing in ${timeUntilRefresh}`}
          </span>
        )}
        <span
          style={{
            position: "absolute",
            right: 42,
            top: 6,
          }}
        >
          {skTypes.QuizStatusDescription[boxScore.quizStatus] +
            (replaySettings ? " (Replaying)" : "")}
        </span>
        <HelpButton
          style={{ position: "absolute", right: 10, top: 0 }}
          tab={watching ? "watch" : "sk"}
        />
      </Row>
    );
  };

  return (
    <div
      className="page"
      style={{
        minWidth: pageMinWidth,
        padding: "5px 14px 5px 5px",
      }}
      onTouchEnd={pointerUp}
      onTouchMove={(e) => {
        if (activeSwipe) {
          setSwipeStatus({
            ...swipeStatus,
            eX: e.touches[0].clientX,
            eY: e.touches[0].clientY,
          });
        }
      }}
    >
      {getHeaderRow()}
      <hr className="separator-line-small" />
      {compactView ? (
        teamViewing === undefined ? (
          <div style={{ padding: 10 }}>
            {boxScore.teams.map((team: skTypes.TeamBoxScore, index: number) => (
              <button
                onClick={() => setTeamViewing(index)}
                style={{
                  height: 45,
                  display: "flex",
                  border: `2px solid ${colors.bqaBlue}`,
                  borderRadius: 5,
                  width: 300,
                  marginBottom: 10,
                  padding: 5,
                  textAlign: "left",
                  backgroundColor: "transparent",
                }}
              >
                <div
                  className="no-wrap-text"
                  style={{
                    fontSize: 22,
                    width: 220,
                    textDecoration: team.eligable ? "" : "line-through",
                  }}
                >
                  {quiz.teams[index].teamName}
                </div>
                <div style={{ fontSize: 22, width: 60 }}>{team.points}</div>
                <div style={{ fontSize: 16, marginTop: 4 }}>{team.errors}</div>
              </button>
            ))}
            <div style={{ fontSize: 12 }}>(Using Compact View)</div>
          </div>
        ) : (
          getTeamDetails(boxScore.teams[teamViewing], teamViewing)
        )
      ) : (
        <>
          <div style={{ display: "flex", marginTop: -5 }}>
            {getAllTeamDetails()}
          </div>
          {!replaySettings ? (
            <Row
              style={{
                maxHeight: "calc(100vh - 320px)",
                marginTop: 10,
              }}
              onMouseEnter={() => setMouseInQs(true)}
              onMouseLeave={() => setMouseInQs(false)}
            >
              <div
                style={{
                  width: `calc(100% - ${advanced ? 400 : 300}px)`,
                  marginRight: 20,
                }}
              >
                {getLastMomentRow()}
                <div
                  style={{
                    border: "1px solid grey",
                    borderRadius: 5,
                    overflowY: "scroll",
                    padding: 4,
                    height: "calc(100% - 30px)",
                  }}
                  data-testid="sk-vq-display"
                >
                  {selectedVerseObj && (
                    <>
                      <p style={{ marginTop: 0, marginBottom: 10 }}>
                        {selectedVerseObj.verse}
                      </p>
                      {selectedWords.length > 0 && (
                        <p style={{ fontSize: 20 }}>
                          {!watching && (
                            <span
                              className={
                                receivedCharacters === 0
                                  ? "received-characters-background"
                                  : ""
                              }
                              onClick={() => {
                                setReceivedCharacters(0);
                                setUnknownFinalWord(false);
                              }}
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          )}
                          {selectedWords.split("").map((char, index) => {
                            return (
                              <span
                                key={`${char}-${index}`}
                                className={
                                  watching ||
                                  !receivedCharacters ||
                                  receivedCharacters <= index
                                    ? undefined
                                    : "received-characters"
                                }
                                style={{
                                  padding: watching ? undefined : "0px 8px",
                                }}
                                onClick={() => {
                                  let newReceived = index + 1;
                                  if (selectedWords.charAt(index + 1) === " ")
                                    newReceived += 1;
                                  setReceivedCharacters(newReceived);
                                }}
                              >
                                {char}
                              </span>
                            );
                          })}
                        </p>
                      )}
                      {possibleQuestions.map((question: string) => (
                        <p key={question} style={{ margin: 0 }}>
                          {question}
                        </p>
                      ))}
                      <br />
                      {impossibleQuestions.map((question: string) => (
                        <p key={question} style={{ margin: 0, fontSize: 12 }}>
                          {question}
                        </p>
                      ))}
                    </>
                  )}
                </div>
              </div>
              <div style={{ width: 250, minHeight: 280, touchAction: "none" }}>
                {selectedBook > 0 && (!ghostSelection || books.length === 1) ? (
                  <>
                    <button
                      className="numpad-reference-display"
                      data-testid="sk-reference"
                      onClick={(e) => {
                        e.preventDefault();
                        clearReference();
                      }}
                    >
                      {(selectedBook > 0
                        ? getShortenedBook(material[selectedBook - 1].bookName)
                        : "") +
                        " " +
                        (selectedChapter > 0 ? selectedChapter : "") +
                        (colonInRef || selectedVerse > 0 ? ":" : "") +
                        (selectedVerse > 0 ? selectedVerse : "")}
                    </button>
                    {getNumberPad()}
                  </>
                ) : (
                  <div
                    style={{
                      minHeight: 250,
                      maxHeight: 300,
                      overflowY: "auto",
                    }}
                  >
                    {books.map((book: number, bookIndex: number) => (
                      <button
                        key={book}
                        onClick={(e) => {
                          e.preventDefault();
                          onClickBookButton(bookIndex);
                        }}
                        className="numpad-button"
                        style={{ width: "47%", marginBottom: 2, height: 50 }}
                      >
                        {getShortenedBook(material[book].bookName)}
                      </button>
                    ))}
                  </div>
                )}
                {!watching && (
                  <>
                    {boxScore.quizStatus !== "ENDED" && (
                      <Row style={{ marginTop: 10 }}>
                        {advanced && (
                          <Check
                            checked={
                              (receivedCharacters && receivedCharacters < 0) ||
                              false
                            }
                            onClick={(checked, e) => {
                              setReceivedCharacters(checked ? -1 : undefined);
                              e.currentTarget.blur();
                            }}
                            outline={
                              !receivedCharacters || receivedCharacters >= 0
                            }
                            style={{
                              width: 90,
                              height: 30,
                              paddingTop: 2,
                              marginRight: 8,
                            }}
                          >
                            Split Ref
                          </Check>
                        )}
                        <div style={{ marginTop: 4 }}>
                          <button className="link" onClick={clickThrowout}>
                            TO
                          </button>
                        </div>
                        <div style={{ marginLeft: 8, marginTop: 4 }}>
                          <button className="link" onClick={clickNoQuestion}>
                            NQ
                          </button>
                        </div>
                        {advanced &&
                          receivedCharacters !== undefined &&
                          receivedCharacters > 0 && (
                            <Check
                              checked={unknownFinalWord}
                              onClick={(checked, e) => {
                                setUnknownFinalWord(checked);
                                e.currentTarget.blur();
                              }}
                              outline={!unknownFinalWord}
                              style={{
                                width: 150,
                                height: 30,
                                paddingTop: 2,
                                marginRight: 8,
                              }}
                            >
                              Unknown Read
                            </Check>
                          )}
                      </Row>
                    )}
                    {user?.securityClearance.logonType === "ADMIN" &&
                      selectedSeason?.importedTeams?.account && (
                        <div style={{ marginTop: 6 }}>
                          <QuizTimer
                            account={selectedSeason.importedTeams.account}
                            round={quiz.round}
                            site={quiz.site}
                            handler={handler}
                          />
                        </div>
                      )}
                  </>
                )}
              </div>
              {selectedVerse > 0 && advanced && !ghostSelection && (
                <div style={{ marginLeft: 10, width: 100 }}>
                  {possibleWords.map((word, wordIndex: number) => {
                    const buttonIsQuote =
                      wordIndex === 0 && word.word === "Quote?";
                    const hasHotkey =
                      !touchscreen &&
                      wordIndex < 10 &&
                      (!buttonIsQuote || hotkeys.quote);
                    return (
                      <button
                        key={word.word}
                        className="word-button no-wrap-text"
                        onClick={(e) => {
                          e.preventDefault();
                          onClickWord(wordIndex);
                        }}
                      >
                        {hasHotkey && (
                          <span className="word-count-span">{`(${
                            buttonIsQuote
                              ? utils.getHotkeyDescription(hotkeys.quote)
                              : wordIndex + (selectedWords.length === 0 ? 0 : 1)
                          })`}</span>
                        )}
                        {`${word.word}${
                          word.count > 1 ? ` - ${word.count}` : ""
                        }`}
                      </button>
                    );
                  })}
                  {possibleQuestions.length === 1 &&
                    selectedWords.length > 0 &&
                    possibleWords.length === 1 && (
                      <button
                        className="word-button no-wrap-text"
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedWords(
                            possibleQuestions[0].split("?")[0] + "?"
                          );
                        }}
                        style={{ fontSize: 12 }}
                      >
                        <span className="word-count-span">{"(2)"}</span>
                        {`[complete]`}
                      </button>
                    )}
                </div>
              )}
            </Row>
          ) : (
            // Replay Mode
            <>
              {getLastMomentRow()}
              <div style={{ marginTop: 20 }}>
                <input
                  type="range"
                  min={0}
                  max={originalBoxScore.totalQuestions}
                  className="slider"
                  value={replaySettings?.selectedQuestion || 0}
                  onChange={(e) => {
                    if (replaySettings)
                      setReplaySettings({
                        ...replaySettings,
                        selectedQuestion: parseInt(e.target.value),
                      });
                  }}
                />
                <p>{`Selected Question: ${replaySettings.selectedQuestion}`}</p>
                <Row
                  style={{ position: "relative", marginBottom: 20, height: 30 }}
                >
                  {replaySettings.selectedQuestion > 0 && (
                    <PlusMinusButton
                      onClick={() => {
                        setReplaySettings({
                          ...replaySettings,
                          selectedQuestion: replaySettings.selectedQuestion - 1,
                        });
                      }}
                    >
                      {"<"}
                    </PlusMinusButton>
                  )}
                  {replaySettings.selectedQuestion <
                    originalBoxScore.totalQuestions && (
                    <PlusMinusButton
                      style={{ position: "absolute", right: 0 }}
                      onClick={() => {
                        setReplaySettings({
                          ...replaySettings,
                          selectedQuestion: replaySettings.selectedQuestion + 1,
                        });
                      }}
                    >
                      {">"}
                    </PlusMinusButton>
                  )}
                </Row>
                <Row style={{ position: "relative" }}>
                  <p style={{ width: "calc(100% - 190px)" }}>
                    Enter the hypothetical questions above. If you have
                    remaining jumps selected for a team, it will be assumed, for
                    win probability calculation purposes, that team gets the
                    corresponding number of correct and incorrect questions and
                    takes no other jumps the rest of the quiz (even if you enter
                    0 correct and incorrect answers).
                  </p>
                  <button
                    className="clickable"
                    style={{ position: "absolute", right: 0 }}
                    onClick={() => {
                      setReplaySettings(undefined);
                    }}
                  >
                    Stop Replaying
                  </button>
                </Row>
              </div>
            </>
          )}
        </>
      )}
      {!touchscreen && quizEditable && (
        <div
          style={{
            // Blue bar on right
            position: "fixed",
            right: 0,
            width: 12,
            height: "50vh",
            top: mouseInQs ? "50vh" : 0,
            backgroundColor: colors.bqaBlue,
          }}
        />
      )}
      <QMRatingSelection
        open={view === "QM_RATING"}
        quiz={quiz}
        onClose={(rating) => {
          handler.runRequest(
            updateQuiz({ ...quiz, qmRating: rating }, user !== undefined)
          );
          setView("SK");
        }}
      />
    </div>
  );
}
