import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../state/store";
import { Check } from "../../components/Check/Check";
import { PlusMinusButton } from "../../components/PlusMinusButton";
import { Row } from "../../components/Row";
import * as skTypes from "../../types/sk";
import * as colors from "../../util/colors";
import { addSeasonToLocalStore, getLocalSeason } from "../../util/skUtils";
import { v4 as uuid } from "uuid";
import { useRequestHandler } from "../../hooks/requestHandler";
import { useDataProvider } from "../../hooks/dataProvider";
import { useKeyedParams } from "../../hooks/useParams";
import {
  addTeamSet,
  getImportableTeamSets,
  getSeasonInfo,
  setSelectedSeason,
  updateSeason,
} from "../../state/actions/skActions";
import "./SK.scss";
import { StringInput } from "../../components/Inputs";
import { Link } from "react-router-dom";

interface EditTeamsProps {
  seasonId: string;
}

export function EditTeams() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { seasonId } = useKeyedParams<EditTeamsProps>();
  const { selectedEvent } = useSelector((state: RootStore) => state.sk);
  const { user } = useSelector((state: RootStore) => state.authentication);

  const getDefaultTeam = (): skTypes.Team => {
    return {
      teamName: "",
      teamId: uuid(),
      quizzers: [
        {
          quizzerName: "",
          quizzerId: uuid(),
          active: true,
        },
      ],
      active: true,
    };
  };

  const [teamData, setTeamData] = useState<skTypes.Team>(getDefaultTeam());
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);

  const [selectedTeam, setSelectedTeam] = useState<number>(-1);
  const [importing, setImporting] = useState<boolean>(false);
  const [importableTeams, setImportableTeams] = useState<
    {
      setName: string;
      setId: string;
      lastUpdated: string;
      teamCount: number;
      changeLog?: string[];
    }[]
  >([]);
  const [confirmImport, setConfirmImport] = useState<number>(-1);

  // Creating and editing and deleting
  const [creatingNewTeam, setCreatingNewTeam] = useState<boolean>(false);
  const [teamEditing, setTeamEditing] = useState<number>(-1); // If -1, creating
  const [lineupsEditing, setLineupsEditing] = useState<boolean>(false);
  const [lineupIndex, setLineupIndex] = useState<number>(-1); // If -1, creating
  const [lineupData, setLineupData] = useState<skTypes.Lineup>(
    {} as skTypes.Lineup
  );

  const [lineupQuizzerSelected, setLineupQuizzerSelected] = useState<
    number | undefined
  >();

  const handler = useRequestHandler({
    onSuccess: (key) => {
      switch (key) {
        case "IMPORT":
          navigate(`/sk/${seasonId}/events`);
          break;
        case "LINEUP_DELETE":
          setLineupData({
            lineupName: "",
            quizzerIdList: teamData.quizzers.map(
              (quizzer: skTypes.Quizzer) => quizzer.quizzerId
            ),
          });
          setLineupIndex(-1);
      }
    },
    assumeCachedInfo: true,
  });
  const provider = useDataProvider({
    getData: getSeasonInfo,
    selector: (state) => state.sk.selectedSeason,
    dispatchAction: setSelectedSeason,
    id: seasonId,
    getId: (season) => season.seasonId,
    fallbackUrl: "/sk/seasons",
    cacheDetails: {
      prefix: "season",
      get: getLocalSeason,
      save: addSeasonToLocalStore,
    },
    render: (selectedSeason) => {
      const saveLineupData = (lineup: skTypes.Lineup): void => {
        // Takes the updated version of the lineup and saves it to the season
        setLineupData(lineup);
        if (!selectedSeason || !lineup.lineupName) return;
        const newSeason = {
          ...selectedSeason,
          teams: [...selectedSeason.teams],
        };
        const team = selectedSeason.teams[selectedTeam];
        if (team.presetLineups) {
          if (lineupIndex === -1) {
            team.presetLineups.push(lineup);
            setLineupIndex(team.presetLineups.length - 1);
          } else {
            team.presetLineups[lineupIndex] = lineup;
          }
        } else {
          team.presetLineups = [lineup];
          setLineupIndex(0);
        }
        newSeason.teams[selectedTeam] = team;

        dispatch(updateSeason(newSeason, user !== undefined));
      };

      const getLineupQuizzerList = (): React.ReactElement[] => {
        const getQuizzerRow = (
          quizzer: skTypes.Quizzer,
          index: number
        ): React.ReactElement => {
          return (
            <div
              style={{ display: "flex", height: 32, marginBottom: 2 }}
              key={quizzer.quizzerId}
            >
              <div
                style={{
                  width: 20,
                  color: lineupData.captain === index ? "red" : "",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (index >= 5) return;
                  saveLineupData({
                    ...lineupData,
                    captain: lineupData.captain === index ? undefined : index,
                  });
                }}
              >{`${index < 5 ? index + 1 : "S"}.`}</div>
              <div
                style={{
                  backgroundColor:
                    lineupQuizzerSelected === index ? colors.bqaBlue : "",
                  minWidth: 150,
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (lineupQuizzerSelected !== undefined) {
                    const newLineup = {
                      ...lineupData,
                      quizzerIdList: [...lineupData.quizzerIdList],
                    };
                    newLineup.quizzerIdList[index] =
                      newLineup.quizzerIdList[lineupQuizzerSelected];
                    newLineup.quizzerIdList[lineupQuizzerSelected] =
                      quizzer.quizzerId;

                    saveLineupData(newLineup);
                    setLineupQuizzerSelected(undefined);
                  } else {
                    setLineupQuizzerSelected(index);
                  }
                }}
              >
                {quizzer.quizzerName}
              </div>
            </div>
          );
        };
        const result = lineupData.quizzerIdList.map(
          (quizzerId: string, index: number) => {
            const quizzer: skTypes.Quizzer = selectedSeason.teams[
              selectedTeam
            ].quizzers.find(
              (quizzerObj: skTypes.Quizzer) =>
                quizzerObj.quizzerId === quizzerId
            ) as skTypes.Quizzer;
            return getQuizzerRow(quizzer, index);
          }
        );
        selectedSeason.teams[selectedTeam].quizzers.forEach(
          (quizzer: skTypes.Quizzer) => {
            if (!lineupData.quizzerIdList.includes(quizzer.quizzerId))
              result.push(getQuizzerRow(quizzer, result.length));
          }
        );
        return result;
      };

      const getTeamListDisplay = (): React.ReactNode[] => {
        const result: React.ReactNode[] = [];
        selectedSeason?.teams.forEach((team: skTypes.Team, index: number) => {
          result.push(
            <div
              key={`team-${team.teamId}`}
              className={`season-event-list ${
                index === selectedTeam ? "season-event-list-active" : ""
              }`}
              style={{ fontWeight: "bold", fontSize: 18, width: "unset" }}
              onClick={() => {
                setSelectedTeam(index);
                setConfirmingDelete(false);
                setLineupsEditing(false);
                window.scrollTo(0, 0);
              }}
            >
              {team.teamName}
            </div>
          );
        });
        return result;
      };
      const getSelectedTeamDisplay = (): React.ReactNode[] => {
        const result: React.ReactNode[] = [];
        selectedSeason?.teams[selectedTeam]?.quizzers.forEach(
          (quizzer: skTypes.Quizzer) => {
            if (quizzer.active) {
              result.push(
                <div
                  key={`quizzer-${quizzer.quizzerId}`}
                  style={{ fontSize: 18 }}
                >
                  {quizzer.quizzerName}
                </div>
              );
            }
          }
        );
        return result;
      };
      const editFormVisible: boolean =
        (teamEditing >= 0 || creatingNewTeam) && !importing;

      if (importing) {
        return (
          <div className="page">
            {handler.snackbar}
            <Row>
              <button
                className="clickable"
                style={{ marginRight: 10 }}
                onClick={() => {
                  setImporting(false);
                }}
                key="cancel"
              >
                Cancel
              </button>
              <h1>Import Team Set</h1>
            </Row>
            <p>
              Click on any of the team sets below to add them to your team
              directory for this season.
            </p>
            {selectedSeason?.importedTeams && (
              <p>
                <span className="important-information">{"NOTE: "}</span>
                {`You are already subscribed to ${
                  selectedSeason.importedTeams.setName ||
                  selectedSeason.importedTeams.setId
                }. Importing a set a
              second time will keep all your stats, but will override your existing
              teams.`}
              </p>
            )}
            {confirmImport >= 0 && (
              <div style={{ marginTop: 5, marginBottom: 20 }}>
                <button
                  className="link"
                  onClick={() => {
                    if (handler.requestInProgress) return;
                    handler.runRequest(
                      addTeamSet(
                        seasonId,
                        importableTeams[confirmImport].setId
                      ),
                      "Adding this team set...",
                      "Team set added!",
                      "IMPORT"
                    );
                  }}
                >
                  <span className="important-information">CONFIRM:</span>&nbsp;
                  {`Import Team Set ${importableTeams[confirmImport].setName}`}
                </button>
              </div>
            )}
            {importableTeams
              .filter(
                (set) =>
                  !selectedSeason?.importedTeams ||
                  set.setId === selectedSeason.importedTeams.setId
              )
              .map((teamSet, index) => (
                <div
                  key={teamSet.setId}
                  className="season-event-list"
                  onClick={() => {
                    setConfirmImport(index);
                  }}
                  style={{
                    marginBottom: 10,
                    paddingBottom: 10,
                    minWidth: 300,
                    height: "auto",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: "bold", fontSize: 16 }}>
                      {teamSet.setName}
                    </span>
                    <span style={{ fontWeight: "normal", fontSize: 14 }}>{`: ${
                      teamSet.teamCount
                    } team${teamSet.teamCount > 1 ? "s" : ""}`}</span>
                  </div>
                  {teamSet.changeLog && (
                    <ul style={{ marginLeft: 5, marginTop: 0 }}>
                      {teamSet.changeLog.map((item: string) => (
                        <li key={item} style={{ fontSize: 12 }}>
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
          </div>
        );
      }

      return (
        <div className="page" style={{ padding: 20 }}>
          {handler.snackbar}
          <Row>
            {!editFormVisible && (
              <Link
                className="back-button"
                style={{ marginRight: 10 }}
                to={`/sk/${seasonId}/${selectedEvent || "events"}`}
              >
                Back to Season
              </Link>
            )}
            <h1>Teams</h1>
          </Row>
          <p>
            <span style={{ marginRight: 20 }}>
              {`Season: ${selectedSeason?.seasonName}`}
            </span>
          </p>
          <Row>
            {!editFormVisible && user && (
              <button
                className="clickable"
                style={{ marginRight: 20 }}
                onClick={async () => {
                  const newImportableTeams = await getImportableTeamSets();
                  setImporting(true);
                  setConfirmImport(-1);
                  setImportableTeams(newImportableTeams);
                }}
              >
                Import Team Set
              </button>
            )}
            {editFormVisible ? (
              <button
                className="back-button"
                key="create-cancel"
                onClick={() => {
                  setCreatingNewTeam(false);
                  setTeamEditing(-1);
                }}
              >
                Cancel
              </button>
            ) : (
              <button
                className="clickable"
                key="create"
                onClick={() => {
                  setCreatingNewTeam(true);
                  setTeamData(getDefaultTeam());
                }}
              >
                Create New Team
              </button>
            )}
          </Row>
          {editFormVisible ? (
            <div>
              <h3 style={{ fontSize: 14, fontWeight: "bold" }}>
                {creatingNewTeam ? "Create Team" : "Edit Team"}
              </h3>
              <StringInput
                caption="Team Name:"
                value={teamData.teamName || ""}
                onChange={(value) =>
                  setTeamData({ ...teamData, teamName: value })
                }
                data-testid="sk-teams-name"
              />
              <StringInput
                caption="Nickname"
                value={teamData.nickname || ""}
                onChange={(value) =>
                  setTeamData({ ...teamData, nickname: value })
                }
              />
              <StringInput
                caption="Coach"
                value={teamData.coach || ""}
                onChange={(value) => setTeamData({ ...teamData, coach: value })}
              />
              {!creatingNewTeam && (
                <Check
                  checked={teamData.active}
                  style={{ width: 200, marginTop: 10 }}
                  onClick={(value: boolean) =>
                    setTeamData({ ...teamData, active: value })
                  }
                >
                  Active
                </Check>
              )}
              <p>Quizzers</p>
              {teamData.quizzers.map(
                (quizzer: skTypes.Quizzer, index: number) => (
                  <StringInput
                    key={quizzer.quizzerId}
                    caption={`${index + 1}. Quizzer Name:`}
                    value={quizzer.quizzerName || ""}
                    onChange={(value) => {
                      const newTeamData = {
                        ...teamData,
                        quizzers: [...teamData.quizzers],
                      };
                      newTeamData.quizzers[index].quizzerName = value;
                      setTeamData(newTeamData);
                    }}
                    afterInput={
                      <>
                        {!creatingNewTeam ? (
                          <Check
                            checked={quizzer.active}
                            onClick={(value: boolean) => {
                              const newTeamData = {
                                ...teamData,
                                quizzers: [...teamData.quizzers],
                              };
                              newTeamData.quizzers[index].active = value;
                              setTeamData(newTeamData);
                            }}
                            style={{
                              marginTop: -4,
                              height: 35,
                              width: 200,
                              marginRight: 10,
                            }}
                          >
                            Active
                          </Check>
                        ) : teamData.quizzers.length > 1 ? (
                          <PlusMinusButton
                            onClick={() => {
                              const newTeamData = {
                                ...teamData,
                                quizzers: teamData.quizzers.filter(
                                  (q, i) => i !== index
                                ),
                              };
                              setTeamData(newTeamData);
                            }}
                            style={{ marginRight: 10 }}
                          >
                            -
                          </PlusMinusButton>
                        ) : null}
                        {index === teamData.quizzers.length - 1 && (
                          <PlusMinusButton
                            onClick={() => {
                              const newTeamData = {
                                ...teamData,
                                quizzers: [...teamData.quizzers],
                              };
                              newTeamData.quizzers.push({
                                quizzerId: uuid(),
                                quizzerName: "",
                                active: true,
                              });
                              setTeamData(newTeamData);

                              const newElement = document.getElementById(
                                `team-quizzer-${
                                  newTeamData.quizzers.length - 1
                                }`
                              );
                              if (newElement) newElement.focus();
                            }}
                          >
                            +
                          </PlusMinusButton>
                        )}
                      </>
                    }
                  />
                )
              )}
              <button
                className="clickable"
                style={{ marginTop: 10 }}
                onClick={() => {
                  if (!teamData.teamName) return;
                  if (selectedSeason) {
                    const newSeason = { ...selectedSeason };
                    if (creatingNewTeam) {
                      newSeason.teams.push(teamData);
                      setCreatingNewTeam(false);
                    } else {
                      newSeason.teams[teamEditing] = teamData;
                      setTeamEditing(-1);
                    }
                    dispatch(updateSeason(newSeason, user !== undefined));
                  }
                }}
              >
                Save Team
              </button>
            </div>
          ) : (
            <>
              <p key="message" className="important-information">
                {selectedSeason.teams.length === 0
                  ? "You don't have any teams yet. Create a new one above or, if you are logged in, import a pre-defined set."
                  : "Click a team name to edit it, create a new one above, or import a set."}
              </p>
              <div style={{ display: "flex", marginTop: 20 }}>
                <div style={{ width: 300 }}>{getTeamListDisplay()}</div>
                {selectedTeam >= 0 && (
                  <>
                    <div style={{ marginLeft: 30, fontSize: 16 }}>
                      <div style={{ fontSize: 24 }}>
                        {selectedSeason?.teams[selectedTeam]?.teamName}
                      </div>
                      {selectedSeason?.teams[selectedTeam]?.coach && (
                        <div style={{ marginBottom: 10 }}>
                          {`Coach: ${selectedSeason?.teams[selectedTeam]?.coach}`}
                        </div>
                      )}
                      {!selectedSeason?.teams[selectedTeam]?.active && (
                        <div
                          className="important-information"
                          style={{ marginBottom: 10 }}
                        >
                          Not Active
                        </div>
                      )}
                      {getSelectedTeamDisplay()}

                      <button
                        className="link"
                        style={{ margin: "15px 0px", paddingLeft: 0 }}
                        onClick={() => {
                          if (selectedSeason) {
                            setTeamEditing(selectedTeam);
                            setTeamData(selectedSeason.teams[selectedTeam]);
                          }
                        }}
                      >
                        Edit Team
                      </button>
                      <br />
                      <button
                        className="link"
                        style={{ marginBottom: 15, paddingLeft: 0 }}
                        onClick={() => {
                          if (selectedSeason) {
                            setLineupsEditing(true);
                            setTeamData(selectedSeason.teams[selectedTeam]);
                            setLineupData({
                              lineupName: "",
                              quizzerIdList: selectedSeason.teams[
                                selectedTeam
                              ].quizzers.map(
                                (quizzer: skTypes.Quizzer) => quizzer.quizzerId
                              ),
                            });
                          }
                        }}
                      >
                        Edit Preset Lineups
                      </button>
                      <br />
                      <button
                        className="link"
                        style={{ paddingLeft: 0 }}
                        onClick={() => {
                          if (!confirmingDelete) {
                            setConfirmingDelete(true);
                            return;
                          }
                          const newSeason: skTypes.Season = {
                            ...selectedSeason,
                            teams: [...(selectedSeason?.teams || [])],
                          } as skTypes.Season;
                          newSeason.teams.splice(selectedTeam, 1);
                          handler.runRequest(
                            updateSeason(newSeason, user !== undefined),
                            "Deleting the team...",
                            "Team deleted!"
                          );
                          setSelectedTeam(-1);
                          setConfirmingDelete(false);
                        }}
                      >
                        {confirmingDelete ? "Delete Team!?" : "Delete Team"}
                      </button>
                    </div>
                    {lineupsEditing && (
                      <div style={{ marginLeft: 30 }}>
                        <h3 style={{ marginTop: 0 }}>Edit Lineups</h3>
                        <p>
                          Lineups are saved automatically. Make sure you provide
                          a lineup name.
                        </p>
                        <select
                          value={lineupIndex}
                          onChange={(e) => {
                            const index: number = Number.parseInt(
                              e.target.value
                            );
                            setLineupIndex(index);
                            const team: skTypes.Team =
                              selectedSeason.teams[selectedTeam];
                            if (index === -1) {
                              setLineupData({
                                lineupName: "",
                                quizzerIdList: team.quizzers.map(
                                  (quizzer: skTypes.Quizzer) =>
                                    quizzer.quizzerId
                                ),
                              });
                            } else if (team.presetLineups !== undefined) {
                              setLineupData({
                                ...team.presetLineups[index],
                                quizzerIdList: [
                                  ...team.presetLineups[index].quizzerIdList,
                                ],
                              });
                            }
                          }}
                        >
                          <option key={-1} value={"-1"}>
                            Create New Lineup
                          </option>
                          {selectedSeason.teams[
                            selectedTeam
                          ].presetLineups?.map(
                            (lineup: skTypes.Lineup, index: number) => (
                              <option
                                key={lineup.lineupName}
                                value={String(index)}
                              >
                                {lineup.lineupName}
                              </option>
                            )
                          )}
                        </select>
                        <StringInput
                          caption="Lineup Name"
                          style={{ marginBottom: 10 }}
                          value={lineupData.lineupName || ""}
                          onChange={(value) => {
                            saveLineupData({
                              ...lineupData,
                              lineupName: value,
                            });
                          }}
                        />
                        {getLineupQuizzerList()}
                        {lineupIndex >= 0 && (
                          <button
                            className="link"
                            onClick={() => {
                              if (!selectedSeason) return;
                              const newSeason = {
                                ...selectedSeason,
                              };
                              const team = selectedSeason.teams[selectedTeam];
                              if (!team.presetLineups) return;
                              team.presetLineups.splice(lineupIndex, 1);
                              newSeason.teams[selectedTeam] = team;
                              handler.runRequest(
                                updateSeason(newSeason, user !== undefined),
                                "Deleting the linup...",
                                "Lineup deleted!"
                              );
                            }}
                          >
                            Delete Lineup
                          </button>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      );
    },
  });

  return provider.getPage();
}
