import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "../../state/store";
import { Row } from "../../components/Row";
import { HelpButton } from "../../components/HelpButton";
import { Check } from "../../components/Check/Check";
import { Selection, StringInput } from "../../components/Inputs";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useDataProvider } from "../../hooks/dataProvider";
import { useRequestHandler } from "../../hooks/requestHandler";
import { useKeyedParams } from "../../hooks/useParams";
import * as utils from "../../util/skUtils";
import * as skTypes from "../../types/sk";
import * as dispatchTypes from "../../state/reducers/sk";
import * as colors from "../../util/colors";
import { arrayToEnum } from "../../util/generalUtil";
import {
  addImportableEvent,
  createSeason,
  deleteSeason,
  getImportableQuizSets,
  getSeasonInfo,
  setSelectedSeason,
  updateSeason,
} from "../../state/actions/skActions";
import { v4 as uuid } from "uuid";
import { ReactComponent as LeftArrow } from "../../icons/left-navigation.svg";
import "./SK.scss";
import { Grid } from "@mui/material";

interface EditEventsProps {
  seasonId: string;
}

export function EditEvents() {
  const { seasonId } = useKeyedParams<EditEventsProps>();
  const provider = useDataProvider({
    getData: getSeasonInfo,
    selector: (state) => state.sk.selectedSeason,
    dispatchAction: setSelectedSeason,
    id: seasonId,
    getId: (season) => season.seasonId,
    render: (season) => {
      return <EditEventsComponent selectedSeason={season} />;
    },
    fallbackUrl: "/sk/seasons",
    cacheDetails: {
      prefix: "season",
      get: utils.getLocalSeason,
      save: utils.addSeasonToLocalStore,
    },
  });
  return provider.getPage();
}

interface Props {
  selectedSeason: skTypes.Season;
}

export function EditEventsComponent(props: Props) {
  const { selectedSeason } = props;
  const { seasonId } = selectedSeason;
  const { user } = useSelector((state: RootStore) => state.authentication);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.selectedSeason.location === "LOCAL" && user)
      dispatch(createSeason(props.selectedSeason));
  }, []);
  useDocumentTitle(`${selectedSeason.seasonName} - BQA Scorekeeper`);

  const rqHandler = useRequestHandler({
    onSuccess: (key) => {
      if (key === "CREATE") {
        dispatch({
          type: dispatchTypes.SET_SK_EVENT,
          payload: {
            selectedEvent: newEventId,
          },
        });
        navigate(`/sk/${seasonId}/${newEventId}`);
      }
      if (key === "DELETE") {
        dispatch(setSelectedSeason());
        setTimeout(() => {
          navigate("/sk/seasons");
        }, 200);
      }
      if (key === "IMPORT") {
        navigate(
          `/sk/${seasonId}/${
            props.selectedSeason.events[props.selectedSeason.events.length - 1]
              .eventId
          }`
        );
        dispatch(setSelectedSeason());
      }
    },
    assumeCachedInfo: true,
    loading: (state) => state.sk.skRequestLoading,
  });

  const { selectedEvent } = useSelector((state: RootStore) => state.sk);
  const [editSeasonName, setEditSeasonName] = useState<string>("");

  const [creatingNewEvent, setCreatingNewEvent] = useState<boolean>(false);
  const [importingEvent, setImportingEvent] = useState<boolean>(false);
  const [importingEventInfo, setImportingEventInfo] = useState<
    | {
        sets: skTypes.QuizSet[];
        teamId?: string;
        eventName?: string;
      }
    | undefined
  >();
  const [newEventName, setNewEventName] = useState<string>("");
  const [newEventId] = useState<string>(uuid());

  const clickImport = async () => {
    const sets = await getImportableQuizSets(
      selectedSeason.importedTeams!?.setId
    );
    setImportingEventInfo({
      sets,
    });
    setImportingEvent(true);
    setNewEventName("");
  };

  if (!selectedSeason) return null;
  return (
    <div className="page" style={{ padding: 20 }}>
      {rqHandler.snackbar}
      <Grid
        container
        spacing={[0, 4]}
        style={{ position: "relative", marginBottom: 10 }}
      >
        <Grid item>
          <h1 style={{ margin: 0 }}>{`${
            selectedSeason.isPractice ? "Practice " : ""
          }${selectedSeason.seasonName}`}</h1>
        </Grid>
        {!editSeasonName && (
          <Grid item style={{ marginTop: 6 }}>
            <button
              className="link"
              style={{ paddingLeft: 0 }}
              onClick={() =>
                setEditSeasonName(selectedSeason?.seasonName || "")
              }
            >
              Edit Season Name
            </button>
          </Grid>
        )}
        <HelpButton style={{ position: "absolute", right: 0, top: 20 }} />
      </Grid>
      <Link className="link" to="/">
        <LeftArrow width={14} height={14} />
        Back to Menu
      </Link>
      <Row style={{ marginTop: 10 }}>
        {creatingNewEvent || importingEvent ? (
          <button
            className="back-button"
            onClick={() => {
              setCreatingNewEvent(false);
              setImportingEvent(false);
            }}
            key="cancel"
          >
            Cancel
          </button>
        ) : (
          <>
            <button
              className="clickable"
              style={{ width: 200, marginRight: 10 }}
              onClick={() => {
                setCreatingNewEvent(true);
                setNewEventName("");
              }}
              key="create-new"
            >
              Create New Event
            </button>
            {!selectedSeason.isPractice && selectedSeason.importedTeams?.setId && (
              <button
                className="clickable"
                style={{ width: 220, marginRight: 10 }}
                onClick={clickImport}
                key="import-event"
              >
                Import Event Schedule
              </button>
            )}
            <Link
              className="clickable"
              to={`/sk/${seasonId}/teams`}
              key="teams"
            >
              Teams
            </Link>
          </>
        )}
        {selectedSeason.events.length === 0 && !creatingNewEvent && (
          <button
            className="link"
            style={{ marginLeft: 10 }}
            onClick={() => {
              rqHandler.runRequest(
                deleteSeason(seasonId, user !== undefined),
                "Deleting the season...",
                "Season deleted!",
                "DELETE"
              );
            }}
          >
            Delete Season
          </button>
        )}
      </Row>
      {!importingEvent && (
        <div
          style={{
            display: "flex",
            border: `4px solid ${colors.bqaDarkGrey}`,
            borderRadius: 8,
            padding: 20,
            marginTop: 10,
          }}
        >
          <div style={{ marginTop: 8 }}>
            <Link className="link" style={{ paddingLeft: 0 }} to="/sk/seasons">
              Back to Seasons
            </Link>
          </div>
          <div
            style={{
              marginLeft: 20,
              display: creatingNewEvent ? "none" : "",
              marginTop: editSeasonName ? -6 : 6,
            }}
          >
            {editSeasonName && (
              <>
                <StringInput
                  caption="Edit Season Name:"
                  value={editSeasonName}
                  onChange={setEditSeasonName}
                  afterInput={
                    <button
                      className="clickable"
                      style={{
                        marginLeft: 15,
                        visibility:
                          editSeasonName !== selectedSeason.seasonName &&
                          !creatingNewEvent
                            ? "visible"
                            : "hidden",
                      }}
                      onClick={() => {
                        const newSeason = {
                          ...selectedSeason,
                          seasonName: editSeasonName,
                        };
                        setSelectedSeason(newSeason);
                        rqHandler.runRequest(
                          updateSeason(newSeason, user !== undefined),
                          "Updating the season...",
                          "UPDATE"
                        );
                      }}
                    >
                      Save
                    </button>
                  }
                />
              </>
            )}
          </div>
        </div>
      )}
      {creatingNewEvent && (
        <div>
          <h2 style={{ marginTop: 20 }}>Create a New Event</h2>
          <StringInput
            caption="New Event Name"
            value={newEventName}
            onChange={setNewEventName}
          />
          <button
            className={`${newEventName.length > 0 ? "" : "disabled-"}clickable`}
            style={{ marginTop: 10 }}
            onClick={() => {
              if (!newEventName) return;

              // Create the new Event
              const newEvent: skTypes.Event = {
                eventName: newEventName,
                eventId: newEventId,
                quizzes: [],
              };
              const newSeason = {
                ...selectedSeason,
                events: [
                  ...selectedSeason.events?.filter(
                    (event: skTypes.Event) => event !== undefined
                  ),
                  newEvent,
                ],
              };
              rqHandler.runRequest(
                updateSeason(newSeason, user !== undefined),
                "Creating the event...",
                "Event created!",
                "CREATE"
              );
            }}
          >
            Create Event
          </button>
        </div>
      )}
      {importingEvent && (
        <div>
          {importingEventInfo ? (
            <>
              <h2 style={{ marginTop: 20 }}>Import Event</h2>
              <p>{`${importingEventInfo.sets.length} event${
                importingEventInfo.sets.length === 1 ? "" : "s"
              } available to import! Select your event and team below:`}</p>
              {importingEventInfo.sets.map((ev) => (
                <Check
                  key={ev.eventName}
                  checked={importingEventInfo.eventName === ev.eventName}
                  onClick={() =>
                    setImportingEventInfo({
                      ...importingEventInfo,
                      eventName: ev.eventName,
                    })
                  }
                  style={{ width: 250 }}
                >
                  {ev.eventName}
                </Check>
              ))}
              <p>
                If the teams have changed recently, make sure to re-import the
                teams first.
              </p>
              <Selection
                caption="Your Team"
                value={importingEventInfo.teamId}
                onChange={(value) =>
                  setImportingEventInfo({
                    ...importingEventInfo,
                    teamId: value,
                  })
                }
                values={arrayToEnum(selectedSeason.teams, (team) => ({
                  key: team.teamId,
                  value: team.teamName,
                }))}
                placeholder="Select Team"
              />
              <div style={{ marginTop: 20 }}>
                {importingEventInfo.teamId &&
                  importingEventInfo.eventName &&
                  user && (
                    <button
                      className="clickable"
                      onClick={() => {
                        rqHandler.runRequest(
                          addImportableEvent(
                            seasonId,
                            importingEventInfo.eventName!,
                            importingEventInfo.teamId!
                          ),
                          "Importing this event...",
                          "Event imported!",
                          "IMPORT"
                        );
                      }}
                    >
                      Import Event
                    </button>
                  )}
              </div>
            </>
          ) : (
            <p>
              There are no events available to import right now. Import a team
              set first.
            </p>
          )}
        </div>
      )}
      {!creatingNewEvent && !importingEvent && (
        <>
          <div
            style={{
              overflowY: "auto",
              marginTop: 10,
              border: `4px solid ${colors.bqaDarkGrey}`,
              borderRadius: 8,
              padding: 20,
            }}
          >
            <p className="important-information" style={{ marginTop: 0 }}>
              {selectedSeason.events?.length
                ? "Select an event below."
                : "You don't have any events. Create one above."}
            </p>
            {selectedSeason?.events?.map((event: skTypes.Event) => (
              <div key={`event-${event?.eventId}`}>
                <Link
                  to={`/sk/${seasonId}/${event.eventId}`}
                  onClick={() => {
                    dispatch({
                      type: dispatchTypes.SET_SK_EVENT,
                      payload: {
                        selectedEvent: event.eventId,
                      },
                    });
                    window.scrollTo(0, 0);
                  }}
                  className={`season-event-list ${
                    event?.eventId === selectedEvent
                      ? "season-event-list-active"
                      : ""
                  }`}
                >
                  {event?.eventName}
                </Link>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
